import {
	GridActionsCellItem,
	GridColDef,
	GridRowParams,
} from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";
import ListPageDX from "../../../components/business/listpagedx";
import { useAuthContext } from "../../../context/authcontext";
import { useNotificationContext } from "../../../context/notificationcontext";
import {
	DateSorter,
	outboundRequestStatusColor,
	DATE_FORMAT,
	API_DATE_FORMAT,
} from "../../../shared/globals";
import useOutboundRequestService from "../../../shared/services/outboundrequestservice";
import TypeTranslator from "../../../shared/typetranslator";
import {
	BackHandOutlined,
	CancelOutlined,
	DocumentScannerOutlined,
	HailOutlined,
	Inventory2Outlined,
	InventoryOutlined,
	LocalShippingOutlined,
	PersonRemoveOutlined,
	TransferWithinAStationOutlined,
	UndoOutlined,
	WhereToVoteOutlined,
} from "@mui/icons-material";
import ConfirmModal from "../../../components/alerts/confirmmodal";
import OutboundReport from "../../../components/pages/reports/outboundrequest/outboundrequestreport";
import OnHoldModal from "../../../components/units_forms/outboundrequests/onholdmodal";
import ReturnedModal from "../../../components/units_forms/outboundrequests/returnedmodal";
import SelfCollectionModal from "../../../components/units_forms/outboundrequests/selfcollectionmodal";
import { useTenantContext } from "../../../context/tenantcontext";
import OrderBulkForm from "../../../components/units_forms/outbound/orderbulkform";
import ActionDropDownMenuDx from "../../../components/controls/dropdownmenudx";
import OutboundStatusUpdateForm from "../../../components/units_forms/outbound/outboundstatusupdateform";

const AllOutboundRequests = (props: any) => {
	const { userData } = useAuthContext();
	const { tenantId } = useTenantContext();
	const { t, i18n } = useTranslation();
	const {
		getOutboundRequests,
		archiveOutboundRequest,
		cancelOutboundRequest,
		unAssignPicker,
		moveToDispatched,
		moveToSelfCollection,
		markAsCompleted,
		moveToReturned,
	} = useOutboundRequestService();
	const { setInfo, setError } = useNotificationContext();
	const [rows, setRows] = useState<any>([]);
	const [dataFromApi, setDataFromApi] = useState<any>([]);
	const [selectedOrders, setSelectedOrders] = useState<any>([]);
	const [openStatusForm, setOpenStatusForm] = useState(false);
	const [statusData, setStatusData] = useState<any>([]);
	const [isLoading, setIsLoading] = useState(false);
	const {
		getOutboundDeliveryTypeValue,
		getOutboundRequestStatusValue,
		getRequestTypeValue,
		outboundRequestStatuses,
	} = TypeTranslator();

	const navigate = useNavigate();

	const columns: GridColDef[] = [
		{
			field: "outboundRequestId",
			headerName: t("Order Number"),
			flex: 1,
		},
		{
			field: "requestDate",
			headerName: `${t("Request Date")}`,
			flex: 1,
			sortComparator: DateSorter,
		},
		{
			field: "outboundDeliveryTypeId",
			headerName: `${t("Order Delivery Type")}`,
			flex: 1,
		},
		{
			field: "scheduleDate",
			headerName: `${t("Schedule Date")}`,
			flex: 1,
			sortComparator: DateSorter,
		},
		{
			field: "pocName",
			headerName: `${t("POC Name")}`,
			flex: 1,
		},
		{
			field: "pocPhone",
			headerName: `${t("POC Phone")}`,
			flex: 1,
			// it has to be a number to be able to sort
			type: "number",
			headerAlign: "left",
			align: "left",
		},
		{
			field: "requestTypeId",
			headerName: t("Request Type"),
			flex: 1,
		},
		{
			field: "statusLabel",
			headerName: `${t("Status")}`,
			flex: 1,
			renderCell: (params: any) => {
				return (
					<div
						style={{
							backgroundColor:
								outboundRequestStatusColor.light[
									outboundRequestStatuses.find(
										(e: any) =>
											e?.en_value === params.value ||
											e?.ar_value === params.value
									)?.id
								] || "white",
							color: `${
								outboundRequestStatusColor.regular[
									outboundRequestStatuses.find(
										(e: any) =>
											e?.en_value === params.value ||
											e?.ar_value === params.value
									)?.id
								]
							}`,
							padding: "5px",
							margin: "5px",
							borderRadius: "4px",
							width: 90,
							textAlign: "center",
						}}
					>
						{params.value}
					</div>
				);
			},
		},
	];
	// Check if userType is 'S'
	if (userData.userType === "S") {
		const customerNameColumn = {
			field: "customerName",
			headerName: `${t("Customer Name")}`,
			flex: 1,
		};
		columns.splice(1, 0, customerNameColumn);
	}

	const buildActions = (params: GridRowParams) => {
		let actionsArray = [];
		const { status } = params.row;

		if (status === 3) {
			actionsArray.push(
				<GridActionsCellItem
					label={t("Assign Picker")}
					showInMenu
					onClick={() => {
						onEdit(params.row);
					}}
					icon={<HailOutlined />}
				/>
			);
		}

		// Picker Assigned
		if (status === 5) {
			actionsArray.push(
				<GridActionsCellItem
					label={t("Start Picking")}
					showInMenu
					onClick={() => {
						handleStartPicking(params.row);
					}}
					icon={<TransferWithinAStationOutlined />}
				/>
			);
			actionsArray.push(
				<GridActionsCellItem
					label={t("Unassign Picker")}
					showInMenu
					onClick={() => {
						setOpenConfirmModal({
							open: true,
							message: t("you want to unassign this picker?"),
							fn: () => handleUnassignPicker(params.row),
						});
					}}
					icon={<PersonRemoveOutlined />}
				/>
			);
		}
		if (status === "Picked") {
			actionsArray.push(
				<GridActionsCellItem
					label={t("Start Packing")}
					showInMenu
					onClick={() => handleStartPacking(params.row)}
					icon={<Inventory2Outlined />}
				/>
			);
		}

		if (status === 8) {
			actionsArray.push(
				<GridActionsCellItem
					label={t("Move to Dispatch")}
					showInMenu
					onClick={() => {
						setOpenConfirmModal({
							open: true,
							message: t("you want to move this request to dispatch?"),
							fn: () => handleMoveToDispatch(params.row),
						});
					}}
					icon={<LocalShippingOutlined />}
				/>
			);

			actionsArray.push(
				<GridActionsCellItem
					label={t("Move to Self Collection")}
					showInMenu
					onClick={() => {
						setOpenConfirmModal({
							open: true,
							message: t("you want to move this request to self collection?"),
							fn: () => handleMoveToSelfCollection(params.row),
						});
					}}
					icon={<HailOutlined />}
				/>
			);

			actionsArray.push(
				<GridActionsCellItem
					label={t("Move to On Hold")}
					showInMenu
					onClick={() => {
						handleMoveToOnHold(params.row);
					}}
					icon={<BackHandOutlined />}
				/>
			);
		}

		if (status === 9) {
			actionsArray.push(
				<GridActionsCellItem
					label={t("Move to Completed")}
					showInMenu
					onClick={() => {
						setOpenConfirmModal({
							open: true,
							message: t("you want to move this request to completed?"),
							fn: () => handleMoveToCompleted(params.row),
						});
					}}
					icon={<WhereToVoteOutlined />}
				/>
			);

			actionsArray.push(
				<GridActionsCellItem
					label={t("Move to Returned")}
					showInMenu
					onClick={() => {
						handleMoveToReturned(params.row);
					}}
					icon={<UndoOutlined />}
				/>
			);
		}
		if (status == 10) {
			actionsArray.push(
				<GridActionsCellItem
					label={t("Collected By Customer")}
					showInMenu
					onClick={() => {
						handleSelfCollection(params.row);
					}}
					icon={<HailOutlined />}
				/>
			);
		}

		if (status === 8 || status == 9 || status == 11 || status == 13) {
			actionsArray.push(
				<GridActionsCellItem
					label={t("Download Document")}
					showInMenu
					onClick={() => {
						setSelectedRequest(params.row);
						setShowPreview(true);
					}}
					icon={<DocumentScannerOutlined />}
				/>
			);
		}

		if (status != 13 && status != 11) {
			actionsArray.push(
				<GridActionsCellItem
					label={t("Cancel Request")}
					showInMenu
					onClick={() => {
						setOpenConfirmModal({
							open: true,
							message: t("you want to cancel this request?"),
							fn: () => handleCancelRequest(params.row),
						});
					}}
					icon={<CancelOutlined />}
				/>
			);
		}
		return actionsArray;
	};

	const handleStartPicking = (data: any) => {
		navigate(`/pickingpanel/${data.outboundRequestId}`);
	};
	const handleStartPacking = (data: any) => {
		navigate(`/packingpanel/${data.outboundRequestId}`);
	};

	const handleUnassignPicker = (data: any) => {
		setIsLoading(true);
		unAssignPicker(data.outboundRequestId)
			.then(() => {
				getData();
				setInfo(t("Picker unassigned successfully"));
			})
			.catch((error: any) => {
				setError(error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleMoveToDispatch = (row: any) => {
		setIsLoading(true);
		moveToDispatched(row.outboundRequestId)
			.then((response: any) => {
				setInfo(t("Request moved to Dispatched successfully"));
				getData();
			})
			.catch((error: any) => {
				setError(error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleSelfCollection = (row: any) => {
		setSelfCollectionModal({ open: true, requestId: row.outboundRequestId });
	};
	const handleCancelRequest = (data: any) => {
		setIsLoading(true);
		cancelOutboundRequest(data.outboundRequestId)
			.then(() => {
				setInfo(t("Request canceled successfully"));
				getData();
			})
			.catch((error: any) => {
				setError(error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleMoveToSelfCollection = (row: any) => {
		setIsLoading(true);
		moveToSelfCollection({
			outboundRequestId: row.outboundRequestId,
			collectionDate: moment().format(API_DATE_FORMAT),
		})
			.then((response: any) => {
				setInfo(t("Request moved to Self Collection successfully"));
				getData();
			})
			.catch((error: any) => {
				setError(error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};
	const handleMoveToOnHold = (row: any) => {
		setSelectedRequest(row);
		setShowOnHoldModal(true);
	};

	const handleMoveToCompleted = (row: any) => {
		setIsLoading(true);
		markAsCompleted(row.outboundRequestId)
			.then((response: any) => {
				setInfo(t("Request has been moved to Completed"));
				getData();
			})
			.catch((error: any) => {
				setError(error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};
	const handleMoveToReturned = (row: any) => {
		setShowReturnModal({
			open: true,
			outboundRequestId: row.outboundRequestId,
		});
	};

	const getData = () => {
		setIsLoading(true);
		const operation =
			userData?.userType === "S"
				? getOutboundRequests(tenantId)
				: getOutboundRequests();
		operation
			.then((response: any) => {
				const updatedRows = response.map((request: any) => ({
					...request,
					requestDate: moment(request.requestDate).format(DATE_FORMAT),
					scheduleDate: moment(request.scheduleDate).format(DATE_FORMAT),
					requestTypeId: getRequestTypeValue(request.requestTypeId),
					outboundDeliveryTypeId: getOutboundDeliveryTypeValue(
						request.outboundDeliveryTypeId
					),
					status: request.status,
					statusLabel: getOutboundRequestStatusValue(request.status),
				}));
				updatedRows.sort((a: any, b: any) =>
					moment(a.requestDate).isBefore(moment(b.requestDate)) ? 1 : -1
				);
				setRows(updatedRows);
				setDataFromApi(updatedRows);
			})
			.catch((error: any) => {
				setError(error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const onDelete = (id: number) => {
		setIsLoading(true);
		archiveOutboundRequest(id)
			.then((response: any) => {
				setInfo(t("Outbound request archived successfully"));
				getData();
			})
			.catch((error: any) => {
				setError(error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const onEdit = (data: any) => {
		navigate(`/orderdetails/${data?.outboundRequestId}`);
	};

	const filterByDate = (startDate: Date, endDate: Date) => {
		if (startDate && endDate) {
			const sd = moment(startDate);
			const ed = moment(endDate);
			const newRows = dataFromApi.filter(({ requestDate }: any) => {
				return moment(requestDate).isBetween(sd, ed, undefined, "[]");
			});
			setRows(newRows);
		} else setRows(dataFromApi);
	};

	const setGridFilterCriteria = (value: string) => {
		if (!value.trim()) {
			setRows(dataFromApi);
			return;
		}
		const newRows = dataFromApi.filter(
			({
				requestDate,
				outboundDeliveryTypeId,
				scheduleDate,
				statusLabel,
				pocPhone,
				pocName,
			}: any) => {
				return (
					requestDate.toLowerCase().includes(value.toLowerCase()) ||
					getOutboundDeliveryTypeValue(outboundDeliveryTypeId)
						.toLowerCase()
						.includes(value.toLowerCase()) ||
					scheduleDate.toLowerCase().includes(value.toLowerCase()) ||
					statusLabel.toLowerCase().includes(value.toLowerCase()) ||
					pocPhone.toString().toLowerCase().includes(value.toLowerCase()) ||
					pocName.toLowerCase().includes(value.toLowerCase())
				);
			}
		);
		setRows(newRows);
	};

	useEffect(() => {
		getData();
	}, [i18n.language, tenantId]);

	const [selectedRequest, setSelectedRequest] = useState<any>(null);
	const [showPreview, setShowPreview] = useState(false);
	const [showOnHoldModal, setShowOnHoldModal] = useState(false);

	const [selfCollectionModal, setSelfCollectionModal] = useState({
		open: false,
		requestId: null,
	});

	const [showReturnModal, setShowReturnModal] = useState({
		open: false,
		outboundRequestId: "",
	});

	const [openConfirmModal, setOpenConfirmModal] = useState<any>({
		open: false,
		message: "",
		fn: null,
	});

	const handleConfirm = () => {
		openConfirmModal.fn();
		handleCloseConfirmModal();
	};

	const handleCloseConfirmModal = () => {
		setOpenConfirmModal({ open: false, message: "", fn: null });
	};

	const onBulkStatusUpdate = () => {
		const ids = dataFromApi.filter((row: any) =>
			selectedOrders.includes(row.outboundRequestId)
		);

		const data = ids.map((row: any) => {
			return {
				id: row.outboundRequestId,
				status: row.status,
				statusLabel: row.statusLabel,
				newStatus: null,
			};
		});

		setStatusData(data);
		setOpenStatusForm(true);
	};

	const [importForm, setImportForm] = useState(false);

	return (
		<>
			{openConfirmModal.open && (
				<ConfirmModal
					open={openConfirmModal.open}
					onYesClick={handleConfirm}
					onNoClick={handleCloseConfirmModal}
					message={openConfirmModal.message}
				/>
			)}
			{showOnHoldModal && (
				<OnHoldModal
					open={showOnHoldModal}
					handleClose={(refresh: boolean) => {
						setShowOnHoldModal(false);
						if (refresh) getData();
					}}
					outboundRequestId={selectedRequest.outboundRequestId}
				/>
			)}
			{showReturnModal.open && (
				<ReturnedModal
					open={showReturnModal.open}
					handleClose={(refresh: boolean) => {
						setShowReturnModal({ open: false, outboundRequestId: "" });
						if (refresh) getData();
					}}
					outboundRequestId={showReturnModal.outboundRequestId}
				/>
			)}
			{selfCollectionModal.open && (
				<SelfCollectionModal
					open={selfCollectionModal.open}
					handleClose={(refresh: boolean) => {
						setSelfCollectionModal({ open: false, requestId: null });

						if (refresh) getData();
					}}
					outboundRequestId={selfCollectionModal.requestId}
				/>
			)}

			{showPreview && (
				<OutboundReport
					open={showPreview}
					onClose={() => setShowPreview(false)}
					requestData={selectedRequest}
				/>
			)}

			{importForm && (
				<OrderBulkForm
					open={importForm}
					handleClose={(refresh: boolean) => {
						setImportForm(false);
						if (refresh) getData();
					}}
				/>
			)}

			{openStatusForm && (
				<OutboundStatusUpdateForm
					open={openStatusForm}
					data={statusData}
					handleClose={(refresh: boolean) => {
						setOpenStatusForm(false);
						if (refresh) getData();
					}}
				/>
			)}

			<ListPageDX
				listTitle={t("Orders")}
				name={t("Orders")}
				rows={rows}
				columns={columns}
				getRowId={(row: any) => row.outboundRequestId}
				isLoading={isLoading}
				onEdit={onEdit}
				editLabel={t("Edit")}
				onDelete={onDelete}
				deleteLabel={t("Archive")}
				setGridFilterCriteria={setGridFilterCriteria}
				exportToPDF={false}
				exportToCSV={false}
				showDatePicker={true}
				filterByDate={filterByDate}
				buildActions={buildActions}
				showImportButton
				onImport={() => setImportForm(true)}
				importTemplateLink={"../assets/templates/UnitsOrdersTemplate.xlsx"}
				checkboxSelection
				rowSelectionModel={selectedOrders}
				onRowSelectionModelChange={(ids: any) => {
					setSelectedOrders(ids);
				}}
				bulkActions={
					<ActionDropDownMenuDx
						label={t("Bulk Actions")}
						actions={[
							{
								label: t("Change Status"),
								onClick: () => {
									if (selectedOrders.length > 0) onBulkStatusUpdate();
									else setError(t("No order selected"));
								},
							},
						]}
					/>
				}
			/>
		</>
	);
};

export default AllOutboundRequests;
