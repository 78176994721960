import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { t } from "i18next";

import GridDX from "../../components/layout/griddx";
import TextFieldDX from "../../components/controls/textfielddx";
import PageTitle from "../../components/layout/pagetitle";

const SallaAuthorizationResponse = (props: any) => {
  const location = useLocation();
  const [token, setToken] = useState<string>("");

  console.log({ location });

  useEffect(() => {
    if (location) {
    }
  }, [location]);

  return <></>;
};

export default SallaAuthorizationResponse;
