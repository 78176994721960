import { t } from "i18next";
import BoxDX from "../../../layout/boxdx";
import DynamicTabs from "../../../layout/dynamictab";
import Utilization from "./utilization";
import GeneralOverview from "./generaloverview";
import GridDX from "../../../layout/griddx";
import PageTitle from "../../../layout/pagetitle";
import UnitsRangePicker from "../../../units_controls/units_rangepicker";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { API_FILTER_DATE_FORMAT } from "../../../../shared/globals";
import { Select, MenuItem, InputLabel, FormControl, SelectChangeEvent } from "@mui/material";

const AdminDashboard = () => {
  const [startDate, setStartDate] = useState<Date | Moment | string | null>(moment().format(API_FILTER_DATE_FORMAT));
  const [endDate, setEndDate] = useState<Date | Moment | string | null>(moment().format(API_FILTER_DATE_FORMAT));
  const [showDatePicker, setShowDatePicker] = useState(true);
  const [requestType, setRequestType] = useState("0");

  const filterByDate = (from: Date, to: Date) => {
    if (from && to) {
      const sd = moment(from).format(API_FILTER_DATE_FORMAT)
      const ed = moment(to).format(API_FILTER_DATE_FORMAT)
      setStartDate(sd);
      setEndDate(ed);
    }
  };

  const handleRequestTypeChange = (event: SelectChangeEvent) => {
    setRequestType(event.target.value);
  };

  return (
    <GridDX container columnSpacing={1} rowSpacing={2} sx={{ width: "100%" }}>
      <GridDX item xs={6} sx={{ marginBottom: 5 }}>
        <PageTitle />
      </GridDX>

      {showDatePicker && <GridDX
        item
        xs={6}
        sx={{
          marginBottom: 5,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <UnitsRangePicker label={t("Date Range")}
          toFilter={filterByDate}
          defaultFilter="currentMonth"
        />
        <FormControl sx={{ minWidth: 150, ml: 2 }} size="small">
          <InputLabel id="request-type-label">{t("Request Type")}</InputLabel>
          <Select
            labelId="request-type-label"
            id="request-type-select"
            value={requestType}
            label="Request Type"
            
            onChange={handleRequestTypeChange}
          >
            <MenuItem value="0">
              {t("All")}
            </MenuItem>
            <MenuItem value="1">{t("B2C")}</MenuItem>
            <MenuItem value="2">{t("B2B")}</MenuItem>
          </Select>
        </FormControl>
      </GridDX>}
      <BoxDX
        sx={{ width: "100%" }}
      >
        <DynamicTabs
          tabLabels={[t("General Overview"), t("Utilization")]}
          tabContents={[<GeneralOverview startDate={startDate} endDate={endDate} requestType={requestType} />, <Utilization />]}
          setShowDatePicker={setShowDatePicker}
        />
      </BoxDX>
    </GridDX>
  );
};

export default AdminDashboard;