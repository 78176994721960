import { useState } from "react";
import { t } from "i18next";

import GridDX from "../../components/layout/griddx";
import TextFieldDX from "../../components/controls/textfielddx";
import PageTitle from "../../components/layout/pagetitle";

const SallaGetAuthorization = () => {
  const [data, setData] = useState<any>({
    clientId: "f64940fa-64a1-4d07-9bb4-5030a4def324",
    clientSecret: "1a4dc2a240e5ff28bdb42ad88299bdd8",
  });

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setData({
      ...data,
      [name]: value,
    });
  };

  //const redirectURL = "https://units.compass-dx.com/sallaauthresponse";
  const redirectURL = "http://localhost:3000/sallaauthresponse";

  const state = Math.random() * 1000000000;

  return (
    <GridDX container columnSpacing={1} rowSpacing={2} sx={{ width: "100%" }}>
      <GridDX item xs={6} sx={{ marginBottom: 5 }}>
        <PageTitle />
      </GridDX>
      <GridDX item xs={12}>
        <TextFieldDX
          label={t("Client Id")}
          name="clientId"
          value={data.clientId}
          onChange={handleInputChange}
          //error={errors["clientId"]}
        />
      </GridDX>
      <GridDX item xs={12}>
        <TextFieldDX
          label={t("Client Secret")}
          name="clientSecret"
          value={data.clientSecret}
          onChange={handleInputChange}
          //error={errors["clientSecret"]}
        />
      </GridDX>
      <GridDX item xs={12}>
        <a
          href={`https://accounts.salla.sa/oauth2/auth?client_id=${data.clientId}&client_secret=${data.clientSecret}&response_type=code&redirect_uri=${redirectURL}&scope=offline_access&state=${state}`}
          target="_new"
        >
          Get Authorization
        </a>
      </GridDX>
    </GridDX>
  );
};

export default SallaGetAuthorization;
