import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import ListPageDX from "../../components/business/listpagedx";
import { useNotificationContext } from "../../context/notificationcontext";
import useUserService from "../../shared/services/userservices";
import { useAuthContext } from "../../context/authcontext";

const Users = () => {
  const { t, i18n } = useTranslation();
  const { setAddRecordfn, setAddRecordLabel } = useOutletContext() as any;
  const { setInfo, setError } = useNotificationContext();
  const { getActiveUsers, deleteUser } = useUserService();

  const [rows, setRows] = useState<any>([]);
  const [dataFromApi, setDataFromApi] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { userData } = useAuthContext();

  const columns: GridColDef[] = [
    {
      field: "fullName",
      headerName: `${t("Name")}`,
      minWidth: 150,
    },
    {
      field: "email",
      headerName: `${t("Email")}`,
      flex: 1,
    },
    {
      field: "mobile",
      headerName: `${t("Mobile")}`,
      flex: 1,
    },
    {
      field: "jobTitle",
      headerName: `${t("Job Title")}`,
      flex: 1,
    },
  ];

  const getData = () => {
    setIsLoading(true);
    getActiveUsers()
      .then((data) => {

        setDataFromApi(data);
        setRows(data);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const toCreate = () => {
    navigate("/userdetails", {
      state: {
        id: null,
      },
    });
  };
  const onEdit = (data: any) => {
    navigate(`/userdetails`, {
      state: {
        userId: data.id,
      },
    });
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    deleteUser(id)
      .then(() => {
        setInfo(t("User deleted successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(dataFromApi);
      return;
    }
    const newRows = dataFromApi.filter(
      (row: any) =>
        row.fullName?.toLowerCase().includes(value.toLowerCase()) ||
        row.email?.toLowerCase().includes(value.toLowerCase()) ||
        row.mobile?.toLowerCase().includes(value.toLowerCase()) ||
        row.jobTitle?.toLowerCase().includes(value.toLowerCase())
    );
    setRows(newRows);
  };

  useEffect(() => {
    getData();
    setAddRecordfn(() => toCreate);
    setAddRecordLabel(t("Add User"));

    return () => {
      setAddRecordfn(() => {});
      setAddRecordLabel(t("Add Record"));
    };
  }, []);

  return (
    <ListPageDX
      listTitle={t("Users")}
      name={t("Users")}
      rows={rows}
      columns={columns}
      getRowId={(row: any) => row.id}
      isLoading={isLoading}
      onEdit={onEdit}
      onDelete={onDelete}
      editLabel={t("Edit")}
      setGridFilterCriteria={setGridFilterCriteria}
      exportToPDF={false}
      exportToCSV={false}
      showDatePicker={false}
      showSelect={false}
    />
  );
};

export default Users;
