import {
  WarehouseOutlined,
  Inventory2Outlined,
  GroupsOutlined,
  ContentPasteOutlined,
  LocalShippingOutlined,
  CalendarViewMonthOutlined,
  CategoryOutlined,
  RefreshOutlined,
} from "@mui/icons-material";
import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import CountBoxDX from "../../../layout/countboxdx";
import GridBoxDX from "../../../layout/gridboxdx";
import GridDX from "../../../layout/griddx";
import useDashboardService from "../../../../shared/services/dashboardservice";
import { useEffect, useState } from "react";
import { useNotificationContext } from "../../../../context/notificationcontext";
import moment from "moment";
import { DATE_FORMAT } from "../../../../shared/globals";
import TypeTranslator from "../../../../shared/typetranslator";
import i18n from "../../../multilingual/i18n";
import AnnualUtilizationChart from "../../../units_components/annualUtilizationBox";
import CustomBarChart from "../../../charts/CustomBarChart";
import { useTenantContext } from "../../../../context/tenantcontext";
import { useAuthContext } from "../../../../context/authcontext";
import { useTheme, Typography, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useOutboundRequestService from "../../../../shared/services/outboundrequestservice";
import BoxDX from "../../../layout/boxdx";
import TypographyDX from "../../../layout/typographydx";
import ItemBox from "../../../units_components/itembox";
import StatusBoxDX from "../../../layout/statusboxdx";
import DataGridDX from "../../../layout/datagriddx";
import CustomPieChart from "../../../charts/CustomPieChart";

// First, create a type for our status mapping
type StatusMappingType = {
  key: string;
  label: string;
  statusId: number;
};

const statusMappings: StatusMappingType[] = [
  { key: 'toBePicked', label: 'To Be Picked', statusId: 3 },
  { key: 'inPicking', label: 'In Picking', statusId: 5 },
  { key: 'toBePacked', label: 'To Be Packed', statusId: 6 },
  { key: 'inPacking', label: 'In Packing', statusId: 7 },
  { key: 'pendingShipment', label: 'Pending Shipment', statusId: 8 },
  { key: 'shipped', label: 'Shipped', statusId: 9 },
];

// Add this after the existing statusMappings
const dispatchMappings: StatusMappingType[] = [
  { key: 'awaitingDispatch', label: 'Orders Awaiting Dispatch', statusId: 8 },
  { key: 'dispatched', label: 'Orders Dispatched', statusId: 9 },
  { key: 'inTransit', label: 'Orders in Transit', statusId: 9 }, // Special case
  { key: 'completed', label: 'Completed Dispatches', statusId: 11 },
];

const GeneralOverview = (props: any) => {
  const theme = useTheme();
  const { outboundRequestStatuses, inboundRequestStatuses, getOutboundRequestStatusId, getOutboundRequestStatusValue, preparePieChartData } = TypeTranslator();
  const { startDate, endDate, requestType } = props;
  const { t } = useTranslation();
  const {
    getStorageSummary,
    getGoodsSummary,
    getCustomerCount,
    getOrderCount,
    getExpiringItemsCount,
    getExpiringContracts,
    getInboundSummaryByStatus,
    getOutboundSummaryByStatus,
  } = useDashboardService();
  const { userData } = useAuthContext();
  const { tenantId }= useTenantContext();
  const { setError } = useNotificationContext();
  const { getOutboundRequests } = useOutboundRequestService();
  const pData = [30, 21, 66, 45, 80, 90, 23, 10, 54, 95, 88, 71];

  const [orderCount, setOrderCount] = useState<any>({
    inbounds: 0,
    outbounds: 0,
    inboundsDelivered: 0,
    outboundsDelivered: 0,
  });
  const [customerCount, setCustomerCount] = useState<any>({
    totalCustomers: 0,
    activeCustomers: 0,
  });
  const [goodsSummary, setGoodsSummary] = useState<any>({
    totalGoods: 112,
    occupiedSpaceInCBM: 73,
  });
  const [storageSummary, setStorageSummary] = useState<any>({
    freeCapacity: 35,
    occupiedCapacity: 65,
    availablePallets: 8,
  });
  const [inboundSummary, setInboundSummary] = useState<any>({
    labels: [],
    data: [],
  });
  const [outboundSummary, setOutboundSummary] = useState<any>({
    labels: [],
    data: [],
  });

  const [expiringItemsCount, setExpiringItemsCount] = useState(0);
  const [expiringContracts, setExpiringContracts] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // New state for Sales Orders mapping, with a key for each derived status
  const [salesOrder, setSalesOrder] = useState({
    toBePicked: 0,
    inPicking: 0,
    toBePacked: 0,
    inPacking: 0,
    pendingShipment: 0,
    shipped: 0,
  });

  // Add this new state
  const [dispatchOrder, setDispatchOrder] = useState({
    awaitingDispatch: 0,
    dispatched: 0,
    inTransit: 0,
    completed: 0,
  });

  // Add these new state variables and functions
  const [outboundRequests, setOutboundRequests] = useState<any[]>([]);
  const { getRequestTypeValue, getOutboundDeliveryTypeValue } = TypeTranslator();

  // Add a new state for filtered orders
  const [allOutboundRequests, setAllOutboundRequests] = useState<any[]>([]);
  const [filteredOutboundRequests, setFilteredOutboundRequests] = useState<any[]>([]);

  // Update the filter effect to use props
  useEffect(() => {
    if (allOutboundRequests.length > 0 && startDate && endDate) {
      const filtered = allOutboundRequests.filter((order: any) => {
        const orderDate = moment(order.requestDate, DATE_FORMAT);
        return orderDate.isBetween(startDate, endDate, 'day', '[]') && order.requestTypeId === requestType.requestTypeId;
      });
      setFilteredOutboundRequests(filtered);
    }
  }, [allOutboundRequests, startDate, endDate, requestType]);

  // Add columns definition for the orders grid
 

  const columns: GridColDef[] = [
    {
      field: "customerName",
      headerName: t("Name"),
      flex: 1,
      maxWidth: 300,
    },
    {
      field: "subscriptionPackageName",
      headerName: t("Package Name"),
      flex: 1,
    },
    {
      field: "endDate",
      headerName: t("Expiration Date"),
      width: 300,
      valueFormatter(params) {
        return moment(params.value).format(DATE_FORMAT);
      },
    },
  ];

  useEffect(() => {
    getData();
  }, [startDate, endDate, i18n.language, tenantId, requestType]);

  const getData = async () => {
    const p1 = userData.userType === "S" ? getOrderCount(tenantId, startDate, endDate,requestType) : getOrderCount(null, startDate, endDate,requestType);
    const p2 = userData.userType === "S" ? getCustomerCount(tenantId, startDate, endDate) : getCustomerCount(null, startDate, endDate);
    const p3 = userData.userType === "S" ? getGoodsSummary(tenantId) : getGoodsSummary();
    const p4 = userData.userType === "S" ? getStorageSummary(tenantId) : getStorageSummary();
    const p5 = userData.userType === "S" ? getExpiringItemsCount(tenantId) : getExpiringItemsCount();
    const p6 = userData.userType === "S" ? getExpiringContracts(tenantId) : getExpiringContracts();
    const p7 = userData.userType === "S" ? getInboundSummaryByStatus(tenantId, startDate, endDate) : getInboundSummaryByStatus(null, startDate, endDate);
    const p8 = userData.userType === "S" ? getOutboundSummaryByStatus(tenantId, startDate, endDate, requestType) : getOutboundSummaryByStatus(null, startDate, endDate, requestType);
    

    setIsLoading(true);
    Promise.all([p1, p2, p3, p4, p5, p6, p7, p8])
      .then(([orders, customers, goods, storage, items, contracts, inbound, outbound]) => {
        setOrderCount(orders);
        setCustomerCount(customers);
        setGoodsSummary(goods);
        setStorageSummary(storage);
        setExpiringItemsCount(items);
        setExpiringContracts(contracts);

        // sort by status
        inbound.sort((a: any, b: any) => a.status - b.status);
        setInboundSummary({
          labels: inbound.map((item: any) => i18n.language === 'en' ? inboundRequestStatuses[item.status].en_value : inboundRequestStatuses[item.status].ar_value),
          data: inbound.map((item: any) => item.count),
        })
        // sort by status
        outbound.sort((a: any, b: any) => a.status - b.status)

        setOutboundSummary({
          labels: outbound.map((item: any) => i18n.language === 'en' ? outboundRequestStatuses[item.status].en_value : outboundRequestStatuses[item.status].ar_value),
          data: outbound.map((item: any) => item.count),
        })
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));

    try {
      const ordersResponse = await getOutboundRequests(tenantId);
      const processedOrders = ordersResponse.map((request: any) => ({
        ...request,
        requestDate: moment(request.requestDate).format(DATE_FORMAT),
        requestTypeId: getRequestTypeValue(request.requestTypeId),
        outboundDeliveryTypeId: getOutboundDeliveryTypeValue(request.outboundDeliveryTypeId),
        statusLabel: getOutboundRequestStatusValue(request.status)
      }));
      setAllOutboundRequests(processedOrders);
    } catch (error) {
      setError(error);
    }
  };

  // Function that maps the counts from outboundSummary to the derived Sales Orders statuses.
  const mapOutboundSummaryToSalesOrder = () => {
    if (!outboundSummary.labels || outboundSummary.labels.length === 0) return;

    // Direct status ID mapping
    const statusMapping: { [key: string]: number } = {
      toBePicked: 3,    // Picker Unassigned
      inPicking: 5,     // Picker Assigned
      toBePacked: 6,    // Picked
      inPacking: 7,     // QC
      pendingShipment: 8, // Packed
      shipped: 9,       // Dispatched
    };

    const mappedSalesOrder = {
      toBePicked: 0,
      inPicking: 0,
      toBePacked: 0,
      inPacking: 0,
      pendingShipment: 0,
      shipped: 0,
    };

    // Loop through outboundSummary data and map to corresponding sales order status
    outboundSummary.data.forEach((count: number, index: number) => {
      const statusLabel = outboundSummary.labels[index];
      const statusId = getOutboundRequestStatusId(statusLabel);
      
      // Find the matching sales order key for this status ID
      const salesOrderKey = Object.keys(statusMapping).find(
        key => statusMapping[key] === statusId
      );

      if (salesOrderKey) {
        mappedSalesOrder[salesOrderKey as keyof typeof mappedSalesOrder] = count;
      }
    });

    setSalesOrder(mappedSalesOrder);
  };

  // Add this new mapping function
  const mapOutboundSummaryToDispatchOrder = () => {
    if (!outboundSummary.labels || outboundSummary.labels.length === 0) return;

    const mappedDispatchOrder = {
      awaitingDispatch: 0,
      dispatched: 0,
      inTransit: 0,
      completed: 0,
    };

    outboundSummary.data.forEach((count: number, index: number) => {
      const statusLabel = outboundSummary.labels[index];
      const statusId = getOutboundRequestStatusId(statusLabel);
      
      // Special handling for in-transit orders
      if (statusId === 9) {
        const excludedStatuses = [11, 12, 13, 14];
        if (!excludedStatuses.includes(statusId)) {
          mappedDispatchOrder.inTransit = count;
        }
      }
      
      // Regular status mapping
      switch(statusId) {
        case 8:
          mappedDispatchOrder.awaitingDispatch = count;
          break;
        case 9:
          mappedDispatchOrder.dispatched = count;
          break;
        case 11:
          mappedDispatchOrder.completed = count;
          break;
      }
    });

    setDispatchOrder(mappedDispatchOrder);
  };

  // Add this to your existing useEffect
  useEffect(() => {
    mapOutboundSummaryToSalesOrder();
    mapOutboundSummaryToDispatchOrder();
  }, [outboundSummary]);

  return (
    <GridDX container columnSpacing={1} rowSpacing={2} sx={{ width: "100%" }}>
      {/* Sales Orders Section */}
      <GridDX item xs={12} mb={2}>
        <BoxDX
          sx={{
            width: "100%",
            backgroundColor: "#ffff",
            p: 2,
            borderColor: "transparent",
            borderRadius: "8px",
            filter: "drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.25))",
           
          }}
        >
          <GridDX container spacing={2}>
            <GridDX item xs={12} display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography fontSize={24} fontWeight="bold" sx={{ mx: 1 }}>
                {t("Sales Orders")}
              </Typography>
              <IconButton onClick={() => getData()}>
                <RefreshOutlined />
              </IconButton>
            </GridDX>

            {/* Status Cards */}
            <GridDX item xs={12}>
              <GridDX container spacing={2}>
                {statusMappings.map((status) => (
                  <GridDX item xs={2} key={status.key}>
                    <StatusBoxDX 
                      onClick={() => navigate(`/orders?filter=${encodeURIComponent(status.statusId)}`)}
                      count={salesOrder[status.key as keyof typeof salesOrder]} 
                      label={t(status.label)} 
                      statusId={status.statusId} 
                    />
                  </GridDX>
                ))}
              </GridDX>
            </GridDX>
          </GridDX>
        </BoxDX>
      </GridDX>

      {/* New Orders and Dispatch Overview Row */}
      <GridDX container spacing={2}>
        {/* New Orders Section */}
        <GridDX item xs={6}>
          <BoxDX
            sx={{
              width: "100%",
              backgroundColor: "#ffff",
              p: 2,
              borderColor: "transparent",
              borderRadius: "8px",
              filter: "drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.25))",
              display: "flex",
              flexDirection: "column",
             
            }}
          >
            <GridDX container spacing={2} sx={{ flex: 1 }}>
              {/* Header */}
              <GridDX item xs={12} display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <Typography fontSize={24} fontWeight="bold" sx={{ mx: 1 }}>
                  {t("New Orders")}
                </Typography>
              </GridDX>
              
              {/* Content */}
              <GridDX item xs={12} sx={{ flex: 1 }}>
                <CustomPieChart
                  dataForPieChart={preparePieChartData(
                    outboundSummary,
                    [5], // Status IDs to highlight (Picker Assigned)
                    t("Picker Assigned"),
                    t("Other Orders"),
                    theme.palette.primary.main,
                    theme.palette.secondary.main
                  )}
                  title=""
                  isLoading={isLoading}
                  filterRange={false}
                  height={250}
                />
              </GridDX>
            </GridDX>
          </BoxDX>
        </GridDX>

        {/* Dispatch Overview Section */}
        <GridDX item xs={6}>
          <BoxDX
            sx={{
              width: "100%",
              backgroundColor: "#ffff",
              p: 2,
              borderColor: "transparent",
              borderRadius: "8px",
              filter: "drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.25))",
              display: "flex",
              flexDirection: "column",
              minHeight: 250,
              maxHeight: 350
            }}
          >
            <GridDX container spacing={2} sx={{ flex: 1, overflow: "hidden" }}>
              {/* Header */}
              <GridDX item xs={12} display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <Typography fontSize={24} fontWeight="bold" sx={{ mx: 1 }}>
                  {t("Dispatch Overview")}
                </Typography>
              </GridDX>

              {/* Content */}
              <GridDX item xs={12} sx={{ flex: 1, overflow: "auto" }}>
                <GridDX container spacing={1}>
                  {dispatchMappings.map((status) => (
                    <GridDX item xs={6} key={status.key}>
                      <StatusBoxDX
                      onClick={() => navigate(`/orders?filter=${encodeURIComponent(status.statusId)}`)}
                        count={dispatchOrder[status.key as keyof typeof dispatchOrder]} 
                        label={t(status.label)} 
                        statusId={status.statusId} 
                      />
                    </GridDX>
                  ))}
                </GridDX>
              </GridDX>
            </GridDX>
          </BoxDX>
        </GridDX>
      </GridDX>

      {/* Original Summary Boxes */}
      <GridDX item xs={3}>
        <CountBoxDX
          title={t("Storage")}
          icon={<WarehouseOutlined color="primary" fontSize="large" />}
          count1={storageSummary.occupiedCapacity}
          label1={t("Total Occupied")}
          count2={storageSummary.freeCapacity}
          label2={t("Free Capacity")}
          loading={isLoading}
        />
      </GridDX>
      <GridDX item xs={3}>
        <CountBoxDX
          title={t("Goods Movement")}
          icon={<Inventory2Outlined color="primary" fontSize="large" />}
          count1={goodsSummary.totalGoods}
          label1={t("Goods")}
          count2={goodsSummary.occupiedSpaceInCBM}
          label2={t("CBM")}
          loading={isLoading}
        />
      </GridDX>
      <GridDX item xs={3}>
        <CountBoxDX
          title={t("Customers")}
          icon={<GroupsOutlined color="primary" fontSize="large" />}
          count1={customerCount.totalCustomers}
          label1={t("Total")}
          count2={customerCount.activeCustomers}
          label2={t("Active")}
          loading={isLoading}
        />
      </GridDX>
      <GridDX item xs={3}>
        <CountBoxDX
          title={t("Orders")}
          icon={<ContentPasteOutlined color="primary" fontSize="large" />}
          count1={orderCount.inbounds}
          label1={t("Inbounds")}
          count2={orderCount.outbounds}
          label2={t("Outbounds")}
          loading={isLoading}
        />
      </GridDX>
      <GridDX item xs={9} mt={1}>
        <AnnualUtilizationChart/>
      </GridDX>
      <GridDX container xs={3} rowSpacing={2} mt={1} pl={1}>
        <GridDX item xs={12}>
          <CountBoxDX
            title={t("Delivered")}
            icon={<LocalShippingOutlined color="primary" fontSize="large" />}
            count1={orderCount.inboundsDelivered}
            label1={t("Inbounds")}
            count2={orderCount.outboundsDelivered}
            label2={t("Outbounds")}
            loading={isLoading}
          />
        </GridDX>
        <GridDX item xs={12}>
          <CountBoxDX
            title={t("Racks Available")}
            icon={
              <CalendarViewMonthOutlined color="primary" fontSize="large" />
            }
            count1={storageSummary.availablePallets}
            loading={isLoading}
          />
        </GridDX>
        <GridDX item xs={12}>
          <CountBoxDX
            title={t("Items Expiring")}
            icon={<CategoryOutlined color="primary" fontSize="large" />}
            count1={expiringItemsCount}
            loading={isLoading}
          />
        </GridDX>
      </GridDX>

      <GridDX item xs={12} mt={1}>
        <CustomBarChart
          title={t("Inbound Requests")}
          pData={inboundSummary.data}
          xLabels={inboundSummary.labels}
          isLoading={false}
          height={350}
          filterRange={false}
          colors={[theme.palette.primary.main]}
          yLabel={t("Requests")}
        />
      </GridDX>
      <GridDX item xs={12} mt={1}>
        <CustomBarChart
          title={t("Orders")}
          pData={outboundSummary.data}
          xLabels={outboundSummary.labels}
          isLoading={false}
          height={350}
          filterRange={false}
          warehouseFilter={false}
          colors={[theme.palette.primary.main]}
          onClick={(label:string) => {
            const statusId = getOutboundRequestStatusId(label);
            if(statusId){
              navigate(`/orders?filter=${encodeURIComponent(statusId)}`)
             };
          }}
          yLabel={t("Requests")}
        />
      </GridDX>
      <GridDX item xs={12}>
        <GridBoxDX
          title={t("Contracts Expiring in 30 Days")}
          rows={expiringContracts}
          columns={columns}
          getRowId={(row: any) => row.subscriptionId}
          loading={isLoading}
        />
      </GridDX>
    </GridDX>
  );
};

export default GeneralOverview;
