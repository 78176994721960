import { useTheme } from "@mui/material";
import BoxDX from "./boxdx";
import TypographyDX from "./typographydx";

interface StatusBoxProps {
  count: number;
  label: string;
  statusId: number;
  onClick?: () => void;
}

const StatusBoxDX = ({ count, label, statusId, onClick }: StatusBoxProps) => {
  const theme = useTheme();
  
  return (
    <BoxDX 
      onClick={onClick}
      sx={{ 
        width: "100%",
        backgroundColor: "#ffff",
        p: 2,
        border: '1px solid #E5E7EB',
        borderRadius: "8px",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100px',
        cursor: 'pointer',
        '&:hover': {
          borderColor: theme.palette.primary.main,
        }
      }}
    >
      <TypographyDX 
        variant="h3" 
        sx={{ 
          color: theme.palette.primary.main,
          fontSize: '2.5rem',
          fontWeight: 600,
          mb: 1
        }}
      >
        {count}
      </TypographyDX>
      <TypographyDX 
        variant="body2" 
        sx={{ 
          color: theme.palette.text.primary,
          fontSize: '0.875rem',
          fontWeight: 500
        }}
      >
        {label}
      </TypographyDX>
    </BoxDX>
  );
};

export default StatusBoxDX; 