import { useTranslation } from "react-i18next";

import ButtonDX from "../controls/buttondx";
import { useState } from "react";
import LoadingButtonDX from "../controls/loadingbuttondx";
import ConfirmModal from "../alerts/confirmmodal";
import GridDX from "../layout/griddx";
import TypographyDX from "../layout/typographydx";
import { Button, Chip } from "@mui/material";
import { PrintOutlined } from "@mui/icons-material";
import {
	inboundRequestStatusColor,
	leadStatusColor,
	outboundRequestStatusColor,
	quotationStatusColor,
} from "../../shared/globals";
import TypeTranslator from "../../shared/typetranslator";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import customerLogo from "../../assets/customer_logo.png";
import "../../assets/fonts/arabic-font-normal.js"; //   // Import the custom font
import { NumberFormat } from "xlsx";
import { useAuthContext } from "../../context/authcontext";

const AddEditEmbeddedModal = (props: any) => {
	const { userData } = useAuthContext();
	const { t, i18n } = useTranslation();

	const languageIsEn = i18n.language === "en";
	const {
		isChanged,
		handleClose,
		closeLabel,
		title,
		onSaveClick,
		isSaving,
		saveLabel,
		isPrintable = false,
		showSaveAndSubmit = false,
		onSaveAndSubmit,
		saveAndSubmitLabel,
		quotationStatus,
		leadRequestStatus,
		leadRequestType,
		inboundRequestStatus,
		onAcceptClick,
		onDeclineClick,
		onProcessClick,
		isProcessInitiated,
		initiateProcess,
		isSkuDataModified,
		onRejectModifications,
		onApproveModifications,
		outboundRequestStatus,
		showMarkCompleted = false,
		onMarkCompleted,
		markAsJunk,
		markAsLost,
		dropDownMenu,
	} = props;
	const [open, setOpen] = useState(false);

	const onClose = () => {
		if (isChanged) setOpen(true);
		else handleClose();
	};
	const {
		getQuotationStatusValue,
		getLeadRequestStatusValue,
		getInboundRequestStatusValue,
		getOutboundRequestStatusValue,
	} = TypeTranslator();
	return (
		<GridDX container columnSpacing={1} rowSpacing={2} sx={{ py: 1 }}>
			<GridDX
				item
				xs={12}
				justifyContent="space-between"
				alignItems="center"
				sx={{ my: 2 }}
			>
				<TypographyDX variant="h5" fontWeight="bold">
					{title}
					{quotationStatus && (
						<Chip
							sx={{
								py: 0,
								ml: 1,
								backgroundColor: quotationStatusColor.regular[quotationStatus],
								color: "#fff",
							}}
							label={getQuotationStatusValue(quotationStatus)}
						/>
					)}
					{leadRequestStatus && (
						<Chip
							sx={{
								py: 0,
								ml: 1,
								backgroundColor: leadStatusColor.regular[leadRequestStatus],
								color: "#fff",
							}}
							label={getLeadRequestStatusValue(leadRequestStatus)}
						/>
					)}
					{inboundRequestStatus && (
						<Chip
							sx={{
								py: 0,
								ml: 1,
								backgroundColor:
									inboundRequestStatusColor.regular[inboundRequestStatus],
								color: "#fff",
							}}
							label={getInboundRequestStatusValue(inboundRequestStatus)}
						/>
					)}
					{outboundRequestStatus && (
						<Chip
							sx={{
								py: 0,
								ml: 1,
								backgroundColor:
									outboundRequestStatusColor.regular[outboundRequestStatus],
								color: "#fff",
							}}
							label={getOutboundRequestStatusValue(outboundRequestStatus)}
						/>
					)}
				</TypographyDX>

				<GridDX item xs={7.2} justifyContent="flex-end" sx={{ my: 2, gap: 8 }}>
					{userData.userType !== "C" && outboundRequestStatus && dropDownMenu}
					{isPrintable && (
						<Button
							variant="contained"
							color="secondary"
							sx={{ color: "#fff" }}
							onClick={props.handleGeneratePDF}
							startIcon={<PrintOutlined />}
							disabled={isSaving}
						>
							{t("Print")}
						</Button>
					)}
					<ButtonDX
						variant="outlined"
						onClick={onClose}
						sx={{ mb: { xs: 2, sm: "auto" }, maxWidth: 120 }}
					>
						{closeLabel ?? t("Close")}
					</ButtonDX>
					{userData.userType === "S" &&
						leadRequestStatus &&
						leadRequestStatus == 1 && (
							<LoadingButtonDX
								color="warning"
								onClick={markAsJunk}
								loading={isSaving}
								sx={{ color: "#fff" }}
							>
								{t("Mark as Junk")}
							</LoadingButtonDX>
						)}
					{userData.userType === "S" &&
						leadRequestStatus &&
						leadRequestStatus == 1 && (
							<LoadingButtonDX
								color="error"
								onClick={markAsLost}
								loading={isSaving}
								sx={{ color: "#fff" }}
							>
								{t("Mark as Lost")}
							</LoadingButtonDX>
						)}
					{!quotationStatus &&
					!leadRequestType &&
					!inboundRequestStatus &&
					!outboundRequestStatus ? (
						<LoadingButtonDX
							color="primary"
							onClick={onSaveClick}
							loading={isSaving}
							sx={{ maxWidth: 160 }}
						>
							{saveLabel ?? t("Save")}
						</LoadingButtonDX>
					) : (
						((quotationStatus && quotationStatus == 1) ||
							leadRequestType == 2 ||
							(inboundRequestStatus && inboundRequestStatus == 1) ||
							(outboundRequestStatus && outboundRequestStatus == 1)) && (
							<LoadingButtonDX
								disabled={leadRequestStatus && leadRequestStatus != 1}
								color="primary"
								onClick={onSaveClick}
								loading={isSaving}
								sx={{ maxWidth: 120 }}
							>
								{saveLabel ?? t("Save")}
							</LoadingButtonDX>
						)
					)}

					{userData.userType !== "C" && showMarkCompleted && (
						<LoadingButtonDX
							color="primary"
							onClick={onMarkCompleted}
							loading={isSaving}
							sx={{ width: 250 }}
						>
							{t("Mark as completed")}
						</LoadingButtonDX>
					)}

					{((userData.userType !== "C" &&
						inboundRequestStatus &&
						inboundRequestStatus === 2) ||
						(userData.userType !== "C" &&
							outboundRequestStatus &&
							outboundRequestStatus === 2)) && (
						<LoadingButtonDX
							color="error"
							onClick={onDeclineClick}
							loading={isSaving}
							// loadingPosition="end"
							sx={{ maxWidth: 120 }}
						>
							{t("Decline")}
						</LoadingButtonDX>
					)}
					{((userData.userType !== "C" &&
						inboundRequestStatus &&
						inboundRequestStatus === 2) ||
						(userData.userType !== "C" &&
							outboundRequestStatus &&
							outboundRequestStatus === 2)) && (
						<LoadingButtonDX
							color="success"
							onClick={onAcceptClick}
							loading={isSaving}
							// loadingPosition="end"
							sx={{ maxWidth: 120, color: "#fff" }}
						>
							{t("Accept")}
						</LoadingButtonDX>
					)}
					{userData.userType !== "C" &&
						(inboundRequestStatus === 3 || outboundRequestStatus === 3) &&
						!isProcessInitiated && (
							<LoadingButtonDX
								color="secondary"
								onClick={initiateProcess}
								loading={isSaving}
								sx={{ maxWidth: 150, color: "#fff" }}
							>
								{inboundRequestStatus ? t("Generate GRN") : t("Assign Picker")}
							</LoadingButtonDX>
						)}

					{userData.userType !== "C" &&
						inboundRequestStatus &&
						inboundRequestStatus === 3 &&
						isProcessInitiated && (
							<LoadingButtonDX
								color="error"
								onClick={() => onProcessClick(9)}
								loading={isSaving}
								// loadingPosition="end"
								sx={{ maxWidth: 120, color: "#fff" }}
							>
								{t("Reject")}
							</LoadingButtonDX>
						)}

					{userData.userType !== "C" &&
						inboundRequestStatus &&
						inboundRequestStatus === 3 &&
						isProcessInitiated &&
						!isSkuDataModified && (
							<LoadingButtonDX
								color="success"
								onClick={() => onProcessClick(8)}
								loading={isSaving}
								// loadingPosition="end"
								sx={{ maxWidth: 120, color: "#fff" }}
							>
								{t("Approve")}
							</LoadingButtonDX>
						)}
					{userData.userType !== "C" &&
						inboundRequestStatus &&
						inboundRequestStatus === 3 &&
						isProcessInitiated &&
						isSkuDataModified && (
							<LoadingButtonDX
								color="secondary"
								onClick={() => onProcessClick(5)}
								loading={isSaving}
								sx={{ maxWidth: 180, color: "#fff" }}
							>
								{t("Send for Approval")}
							</LoadingButtonDX>
						)}

					{userData.userType === "C" &&
						inboundRequestStatus &&
						inboundRequestStatus === 5 && (
							<LoadingButtonDX
								color="error"
								onClick={onRejectModifications}
								loading={isSaving}
								// loadingPosition="end"
								sx={{ maxWidth: 200, color: "#fff" }}
							>
								{t("Reject Modifications")}
							</LoadingButtonDX>
						)}
					{userData.userType === "C" &&
						inboundRequestStatus &&
						inboundRequestStatus === 5 && (
							<LoadingButtonDX
								color="success"
								onClick={onApproveModifications}
								loading={isSaving}
								// loadingPosition="end"
								sx={{ maxWidth: 215, color: "#fff" }}
							>
								{t("Approve Modifications")}
							</LoadingButtonDX>
						)}

					{showSaveAndSubmit && (
						<LoadingButtonDX
							color="primary"
							onClick={() =>
								onSaveAndSubmit ? onSaveAndSubmit() : onSaveClick(2)
							}
							loading={isSaving}
							// loadingPosition="end"
							sx={{ maxWidth: languageIsEn ? 180 : 240 }}
						>
							{saveAndSubmitLabel ?? t("Save & Submit")}
						</LoadingButtonDX>
					)}
				</GridDX>
			</GridDX>
			{open && (
				<ConfirmModal
					open={open}
					onYesClick={handleClose}
					onNoClick={() => setOpen(false)}
				/>
			)}
			<GridDX
				container
				columnSpacing={1}
				rowSpacing={2}
				sx={{
					backgroundColor: "#fff",
					borderRadius: "8px",
					boxShadow: "0px 0px 13px 1px #00000008",
					ml: "10px",
					p: 2,
				}}
			>
				{props.children}
			</GridDX>
		</GridDX>
	);
};

export default AddEditEmbeddedModal;
