import {
	Dialog,
	DialogContent,
	DialogTitle,
	Typography,
	Chip,
} from "@mui/material";
import BoxDX from "../../layout/boxdx";
import GridDX from "../../layout/griddx";
import { useTranslation } from "react-i18next";
import { useRef, useState, useEffect } from "react";
import { read, utils } from "xlsx";
import ButtonDX from "../../controls/buttondx";
import LoadingButtonDX from "../../controls/loadingbuttondx";
import { useNotificationContext } from "../../../context/notificationcontext";
import TypeTranslator from "../../../shared/typetranslator";
import { request } from "http";
import { arraysHaveSameElements } from "../../../shared/globals";

const ItemBulkForm = (props: any) => {
	const { t } = useTranslation();
	const { setInfo, setError } = useNotificationContext();
	const { open, handleClose, setBulkFile } = props;
	const inputRef = useRef<HTMLInputElement>(null);

	const handleClick = () => inputRef.current?.click();

	const validateFile = async (file: File) => {
		const reqHeaders = [
			"SkuCode",
			"SkuName",
			"Quantity",
			"PackagingType",
			"Notes",
		];

		const buffer = await file.arrayBuffer();
		const workbook = read(buffer, { type: "array" });
		const worksheet = workbook.Sheets[workbook.SheetNames[0]];
		const data = utils.sheet_to_json(worksheet, { header: 1 });
		const headers = data[0] as string[];

		return arraysHaveSameElements(reqHeaders, headers);
	};

	const onChangeHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const valid = await validateFile(e.target.files[0]);
			if (valid) {
				setBulkFile(e.target.files[0]);
				handleClose(false);
			} else setError(t("No valid rows to import"));
		}
	};

	const onDragDropHandler = async (e: any) => {
		e.preventDefault();
		const files = e.dataTransfer.files;
		if (files.length > 0) {
			const valid = await validateFile(files[0]);
			if (valid) {
				setBulkFile(files[0]);
				handleClose(false);
			} else setError(t("No valid rows to import"));
		}
	};

	return (
		<Dialog open={open} fullWidth maxWidth="md">
			<DialogTitle>
				<Typography variant="h6">{t("Bulk Import Items")}</Typography>
			</DialogTitle>
			<DialogContent>
				<GridDX container spacing={2}>
					<GridDX item xs={12}>
						<BoxDX
							onDragOver={(e: any) => e.preventDefault()}
							onDrop={onDragDropHandler}
							sx={{
								border: "1px dashed #c6c6c6",
								borderRadius: 1,
								width: "100%",
								height: 150,
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<input
								type="file"
								ref={inputRef}
								hidden
								accept=".xlsx, .xls, .csv"
								onChange={onChangeHandler}
							/>
							<BoxDX
								display="flex"
								flexDirection="column"
								alignItems="center"
								gap={1}
							>
								<Typography sx={{ fontSize: 18, textAlign: "center" }}>
									{t("Drag and Drop File here or")}
								</Typography>
								<ButtonDX
									variant="text"
									onClick={handleClick}
									sx={{ textAlign: "center" }}
								>
									{t("Browse")}
								</ButtonDX>
							</BoxDX>
						</BoxDX>
					</GridDX>
				</GridDX>
			</DialogContent>
			<GridDX container justifyContent="flex-end" p={2} gap={2}>
				<ButtonDX onClick={() => handleClose(false)}>{t("Close")}</ButtonDX>
			</GridDX>
		</Dialog>
	);
};

export default ItemBulkForm;
