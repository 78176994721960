import { GridColDef } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext, useNavigate } from "react-router-dom";
import ListPageDX from "../../components/business/listpagedx";
import { useNotificationContext } from "../../context/notificationcontext";
import GridDX from "../../components/layout/griddx";
import BoxDX from "../../components/layout/boxdx";
import SelectListDX from "../../components/controls/selectlistdx";
import useWarehouseService from "../../shared/services/warehouseservice";
import useWarehouseCarrierService from "../../shared/services/warehousecarrierservice";
import CarrierBulkForm from "../../components/units_forms/carriers/carriersbulkform";
import { useAuthContext } from "../../context/authcontext";
import { useTenantContext } from "../../context/tenantcontext";

const Carriers = (props: any) => {
  const { t, i18n } = useTranslation();
  const { setAddRecordfn, setAddRecordLabel } = useOutletContext() as any;
  const { userData } = useAuthContext();
  const { tenantId } = useTenantContext();
  const { setInfo, setError } = useNotificationContext();
  const { getWarehouses } = useWarehouseService();
  const { getWarehouseCarriersByWarehouseId, archiveWarehouseCarrier } = useWarehouseCarrierService();
  const navigate = useNavigate();

  const [rows, setRows] = useState<any>([]);
  const [dataFromApi, setDataFromApi] = useState<any>([]);
  const [warehouseId, setWarehouseId] = useState<any>(null);
  const [warehouses, setWarehouses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [importForm, setImportForm] = useState(false);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: `${t("Name")}`,
      flex: 1,
    },
    {
      field: "code",
      headerName: `${t("Code")}`,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: "pocName",
      headerName: `${t("Contact Person")}`,
      minWidth: 200,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: "email",
      headerName: `${t("Email")}`,
      minWidth: 200,
    },
  ];

  useEffect(() => {
    getData();
  }, [warehouseId]); 

  useEffect(() => {
    getData();
    getWarehouseList();
    setAddRecordfn(() => toCreate);
    setAddRecordLabel(t("Add Carrier"));

    return () => {
        setAddRecordfn(() => {});
        setAddRecordLabel(t("Add Record"));
    };
  }, [i18n.language]);

  useEffect(() => {
    getWarehouseList();
  }, [tenantId]); 

  const getData = () => {
    if (warehouseId) {
      setIsLoading(false);
      getWarehouseCarriersByWarehouseId(warehouseId)
        .then((data) => {
          setDataFromApi(data);
          setRows(data);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
    };

  const getWarehouseList = async () => {
    const operation = userData?.userType === "S" ? getWarehouses(tenantId) : getWarehouses();
    operation
      .then((res) => {
        setWarehouses(
          res.map((item: any) => {
            return { text: item.name, value: item.warehouseId };
          })
        );
      })
      .catch((err) => setError(err));
  };

  const toCreate = () => {
    navigate("/warehousecarrierdetails", {
      state: {},
    });
  };

  const onEdit = (data: any) => {
    navigate(`/warehousecarrierdetails`, {
      state: {
        carrierId: data.warehouseCarrierId,
      },
    });
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    archiveWarehouseCarrier(id)
      .then(() => {
        setInfo(t("Carrier has been archived successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };
  
  const toImport = () => {
    setImportForm(true);
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(dataFromApi);
      return;
    }
    const newRows = dataFromApi.filter(
      (row: any) =>
        row.name.toLowerCase().includes(value.toLowerCase()) ||
        row.startTime.toLowerCase().includes(value.toLowerCase()) ||
        row.endTime.toLowerCase().includes(value.toLowerCase())
    );
    setRows(newRows);
  };

  const handleInputChange = (e: any) => {
    const { value } = e.target;
    setWarehouseId(value);
  };
  
  const onClose = (refreshPage: boolean = false) => {
    setImportForm(false);

    if (refreshPage) getData();
  };

  return (
    <GridDX sx= {{ flexDirection: "column", alignItems: "flex-end", overflow: 'hidden'}} >
      {importForm && <CarrierBulkForm open={importForm} handleClose={onClose} />}
      <GridDX
        item
        xs={12}
        sx={{
          justifyContent: "flex-end",
          alignItems: "center",
        }}
        mt={1}
      >
        <BoxDX width={350}>
          <SelectListDX
            label={t("Select Warehouse")}
            name="warehouseId"
            items={warehouses}
            value={warehouseId}
            onChange={handleInputChange}
          />
        </BoxDX>
      </GridDX>
      <ListPageDX
        listTitle={t("WarehouseCarriers")}
        name={t("Warehouse Carriers")}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.warehouseCarrierId}
        isLoading={isLoading}
        onEdit={onEdit}
        onDelete={onDelete}
        deleteLabel={t("Archive")}
        editLabel={t("Preview")}
        setGridFilterCriteria={setGridFilterCriteria}
        exportToPDF={false}
        exportToCSV={false}
        showDatePicker={false}
        showSelect={false}
        showImportButton
        onImport={toImport}
        importTemplateLink={"../assets/templates/UnitsCarrierTemplate.csv"}
      />
    </GridDX>
  );
};

export default Carriers;
