import { GridColDef } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext, useNavigate } from "react-router-dom";
import ListPageDX from "../../components/business/listpagedx";
import { useNotificationContext } from "../../context/notificationcontext";
import useBrandService from "../../shared/services/brandservice";
import BrandBulkForm from "../../components/units_forms/brands/brandsbulkform";

const Brands = (props: any) => {
  const { t, i18n } = useTranslation();
  const { setAddRecordfn, setAddRecordLabel } = useOutletContext() as any;
  const { setInfo, setError } = useNotificationContext();
  const { getBrands, archiveBrand } = useBrandService();

  const [rows, setRows] = useState<any>([]);
  const [dataFromApi, setDataFromApi] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [importForm, setImportForm] = useState(false);
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: `${t("Name")}`,
      minWidth: 150,
      flex: 1,
    },
  ];

  const getData = () => {
    setIsLoading(true);
    getBrands()
      .then((data) => {
        setDataFromApi(data);
        setRows(data);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const toCreate = () => {
    navigate("/branddetails", {
      state: {
        packageData: null,
      },
    });
  };
  const onEdit = (data: any) => {
    navigate(`/branddetails`, {
      state: {
        packageData: data,
      },
    });
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    archiveBrand(id)
      .then(() => {
        setInfo(t("Brand archived successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const toImport = () => {
    setImportForm(true);
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(dataFromApi);
      return;
    }
    const newRows = dataFromApi.filter(
      (row: any) =>
        row.name.toLowerCase().includes(value.toLowerCase()) ||
        row.code.toLowerCase().includes(value.toLowerCase())
    );
    setRows(newRows);
  };

  const onClose = (refreshPage: boolean = false) => {
    setImportForm(false);

    if (refreshPage) getData();
  };

  useEffect(() => {
    getData();
    setAddRecordfn(() => toCreate);
    setAddRecordLabel(t("Add Brand"));

    return () => {
        setAddRecordfn(() => {});
        setAddRecordLabel(t("Add Record"));
    };
  }, [i18n.language]);

  return (
    <>
      {importForm && <BrandBulkForm open={importForm} handleClose={onClose} />}
      <ListPageDX
        listTitle={t("Brands")}
        name={t("Brands")}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.brandId}
        isLoading={isLoading}
        onEdit={onEdit}
        onDelete={onDelete}
        editLabel={t("Preview")}
        setGridFilterCriteria={setGridFilterCriteria}
        exportToPDF={false}
        exportToCSV={false}
        showDatePicker={false}
        showSelect={false}
        showImportButton
        onImport={toImport}
        importTemplateLink={"../assets/templates/UnitsBrandTemplate.csv"}
      />
    </>
  );
};

export default Brands;
