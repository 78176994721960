import { useTranslation } from "react-i18next";
import GridDX from "../../layout/griddx";
import PageTitle from "../../layout/pagetitle";
import CountBoxDX from "../../layout/countboxdx";
import {
  ContentPasteOutlined,
  PendingActionsOutlined,
} from "@mui/icons-material";
import GridBoxDX from "../../layout/gridboxdx";
import { GridColDef } from "@mui/x-data-grid";
import useDashboardService from "../../../shared/services/dashboardservice";
import { useNotificationContext } from "../../../context/notificationcontext";
import { useEffect, useState } from "react";
import moment from "moment";
import { API_FILTER_DATE_FORMAT, DATE_FORMAT, outboundRequestStatusColor,inboundRequestStatusColor } from "../../../shared/globals";
import UnitsRangePicker from "../../units_controls/units_rangepicker";
import TypeTranslator from "../../../shared/typetranslator";
import i18n from "../../multilingual/i18n";
import CustomBarChart from "../../charts/CustomBarChart";

const CustomerDashboard = () => {
  const { t } = useTranslation();
  const { getOrderCount, getExpiringContracts, getNearyExpirySkus, getLowStockSkus, getInboundSummaryByStatus, getOutboundSummaryByStatus } = useDashboardService();
  const { setError } = useNotificationContext();
  const { outboundRequestStatuses, inboundRequestStatuses } = TypeTranslator()
   const [orderCount, setOrderCount] = useState<any>({
    inbounds: 0,
    outbounds: 0,
    inboundsPending: 0,
    outboundsPending: 0,
  });
  const [inboundSummary, setInboundSummary] = useState<any>({
    labels: [],
    data: [],
    colors: [],
  });
  const [outboundSummary, setOutboundSummary] = useState<any>({
    labels: [],
    data: [],
    colors: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [expiringContracts, setExpiringContracts] = useState<any>([]);
  const [nearyExpirySkus, setNearyExpirySkus] = useState<any>([]);
  const [lowStockSkus, setLowStockSkus] = useState<any>([]);
  const [startDate, setStartDate] = useState<string | null>(moment().format(API_FILTER_DATE_FORMAT));
  const [endDate, setEndDate] = useState<string | null>(moment().format(API_FILTER_DATE_FORMAT));
  const filterByDate = (from: Date, to: Date) => {
    if (from && to) {
      const sd = moment(from).format(API_FILTER_DATE_FORMAT)
      const ed = moment(to).format(API_FILTER_DATE_FORMAT)
      setStartDate(sd);
      setEndDate(ed);
    }
  };
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("Package Name"),
      flex: 1,
      valueGetter(params) {
        return params.row.subscriptionPackage.name;
      },
    },
    {
      field: "endDate",
      headerName: t("Expiration Date"),
      width: 300,
      valueFormatter(params) {
        return moment(params.value).format(DATE_FORMAT);
      },
    },
  ];

  const stockColumns: GridColDef[] = [
    {
      field: "skuName",
      headerName: t("Name"),
      flex: 1,
    },
    {
      field: "stockRemaining",
      headerName: t("Remaining Stock"),
      width: 150,
    },
  ];

  const expiryColumns: GridColDef[] = [
    {
      field: "skuName",
      headerName: t("Name"),
      flex: 1,
    },
    {
      field: "daysToExpiry",
      headerName: t("Days till Expiration"),
      width: 150,
    },
    {
      field: "expiryDate",
      headerName: t("Expiration Date"),
      width: 150,
      valueFormatter(params) {
        return moment(params.value).format(DATE_FORMAT);
      },

    },
  ];

  useEffect(() => {
    getData();
  }, [startDate, endDate,i18n.language]);

  const getData = async () => {
    const p1 = getOrderCount(null, startDate, endDate,null);
    const p2 = getExpiringContracts();
    const p3 = getNearyExpirySkus();
    const p4 = getLowStockSkus();
    const p5 = getInboundSummaryByStatus(null, startDate, endDate);
    const p6 = getOutboundSummaryByStatus(null, startDate, endDate, null);

    setIsLoading(true);
    Promise.all([p1, p2, p3, p4, p5, p6])
      .then(([orders, contracts, nearyExpirySkus, lowStockSkus, inbound, outbound]) => {
        setOrderCount(orders);
        setExpiringContracts(contracts);
        setNearyExpirySkus(nearyExpirySkus);
        setLowStockSkus(lowStockSkus);

        // sort by status
        inbound.sort((a: any, b: any) => a.status - b.status);
        setInboundSummary({
          labels: inbound.map((item: any) => i18n.language === 'en' ? inboundRequestStatuses[item.status].en_value : inboundRequestStatuses[item.status].ar_value),
          data: inbound.map((item: any) => item.count),
          colors: inbound.map((item: any) => inboundRequestStatusColor.regular[item.status])
        })
        // sort by status
        outbound.sort((a: any, b: any) => a.status - b.status)

        setOutboundSummary({
          labels: outbound.map((item: any) => i18n.language === 'en' ? outboundRequestStatuses[item.status].en_value : outboundRequestStatuses[item.status].ar_value),
          data: outbound.map((item: any) => item.count),
          colors: outbound.map((item: any) => outboundRequestStatusColor.regular[item.status])
        })
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  return (
    <GridDX container columnSpacing={1} rowSpacing={2} sx={{ width: "100%" }}>
      <GridDX item xs={6} sx={{ marginBottom: 5 }}>
        <PageTitle />
      </GridDX>
      <GridDX
        item
        xs={6}
        sx={{
          marginBottom: 5,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <UnitsRangePicker label={t("Date Range")}
          toFilter={filterByDate}
          defaultFilter="currentMonth"
        />
      </GridDX>
      <GridDX container xs={3} rowSpacing={2} mt={1} pl={1}>
        <GridDX item xs={12} mb={3}>
          <CountBoxDX
            title={t("Orders")}
            icon={<ContentPasteOutlined color="primary" fontSize="large" />}
            count1={orderCount.inbounds}
            label1={t("Inbounds")}
            count2={orderCount.outbounds}
            label2={t("Outbounds")}
            loading={isLoading}
          />
        </GridDX>
        <GridDX item xs={12}>
          <CountBoxDX
            title={t("Pending Approval")}
            icon={<PendingActionsOutlined color="primary" fontSize="large" />}
            count1={orderCount.inboundsPending}
            label1={t("Inbounds")}
            count2={orderCount.outboundsPending}
            label2={t("Outbounds")}
            loading={isLoading}
          />
        </GridDX>
      </GridDX>
      <GridDX item xs={9} mt={1}>
        <GridBoxDX
          title={t("Subscriptions Expiring in 30 Days")}
          rows={expiringContracts}
          columns={columns}
          getRowId={(row: any) => row.subscriptionId}
          loading={isLoading}
        />
      </GridDX>
      <GridDX item xs={12} mt={1}>
        <CustomBarChart
          title={t("Inbound Requests")}
          pData={inboundSummary.data}
          xLabels={inboundSummary.labels}
          isLoading={false}
          height={350}
          filterRange={false}
          colors={["#AD70FF"]}
          yLabel={t("Requests")}
        />
      </GridDX>
      <GridDX item xs={12} mt={1}>
        <CustomBarChart
          title={t("Outbound Requests")}
          pData={outboundSummary.data}
          xLabels={outboundSummary.labels}
          isLoading={false}
          height={350}
          filterRange={false}
          warehouseFilter={false}
          colors={["#AD70FF"]}
          yLabel={t("Requests")}
        />
      </GridDX>
      <GridDX item xs={6}>
        <GridBoxDX
          title={t("Low Stock Items")}
          rows={lowStockSkus}
          columns={stockColumns}
          getRowId={(row: any) => row.skuName}
        />
      </GridDX>
      <GridDX item xs={6}>
        <GridBoxDX
          title={t("Near Expiry Items")}
          rows={nearyExpirySkus}
          columns={expiryColumns}
          getRowId={(row: any) => row.skuName}
        />
      </GridDX>
    </GridDX>
  );
};

export default CustomerDashboard;
