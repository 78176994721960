import useSecureAPI from "../hooks/usesecureapi";

const useDashboardService = () => {
  const secureAPI = useSecureAPI();
  const getOrderCount = async (
    tenantId: number | null = null,
    startDate: string | null,
    endDate: string | null,
    requestType: number | null
  ) => {
    const url = tenantId
      ? `Dashboard/orderCount/${startDate}/${endDate}/${requestType}?tenantId=${tenantId}`
      : `Dashboard/orderCount/${startDate}/${endDate}/${requestType}`;
    const response = await secureAPI.get(url);
    return response.data;
  };

  const getCustomerCount = async (
    tenantId: number | null = null,
    startDate: string | null,
    endDate: string | null
  ) => {
    const url = tenantId
      ? `Dashboard/customerCount/${startDate}/${endDate}?tenantId=${tenantId}`
      : `Dashboard/customerCount/${startDate}/${endDate}`;
    const response = await secureAPI.get(url);
    return response.data;
  };

  const getGoodsSummary = async (tenantId: number | null = null) => {
    const url = tenantId
      ? `Dashboard/goodsSummary?tenantId=${tenantId}`
      : `Dashboard/goodsSummary`; // {"totalGoods":6,"occupiedSpaceInCBM":0}
    const response = await secureAPI.get(url);
    return response.data;
  };

  const getStorageSummary = async (tenantId: number | null = null) => {
    // {"freeCapacity":100.00,"occupiedCapacity":0.00,"availablePallets":2}
    const url = tenantId
      ? `Dashboard/storageSummary?tenantId=${tenantId}`
      : `Dashboard/storageSummary`;
    const response = await secureAPI.get(url);
    return response.data;
  };

  const getExpiringItemsCount = async (tenantId: number | null = null) => {
    const url = tenantId
      ? `Dashboard/expiringItemsCount?tenantId=${tenantId}`
      : `Dashboard/expiringItemsCount`;
    const response = await secureAPI.get(url);
    return response.data;
  };

  const getExpiringContracts = async (tenantId: number | null = null) => {
    const url = tenantId
      ? `Dashboard/expiringContracts?tenantId=${tenantId}`
      : `Dashboard/expiringContracts`;
    const response = await secureAPI.get(url);
    return response.data;
  };
  const getNearyExpirySkus = async (tenantId: number | null = null) => {
    const url = tenantId
      ? `Dashboard/nearyexpiryskus?tenantId=${tenantId}`
      : `Dashboard/nearyexpiryskus`;
    const response = await secureAPI.get(url);
    return response.data;
  };
  const getLowStockSkus = async (tenantId: number | null = null) => {
    const url = tenantId
      ? `Dashboard/lowstockskus?tenantId=${tenantId}`
      : `Dashboard/lowstockskus`;
    const response = await secureAPI.get(url);
    return response.data;
  };
  const getInboundSummaryByStatus = async (
    tenantId: number | null = null,
    startDate: string | null,
    endDate: string | null
  ) => {
    if (!startDate || !endDate) {
      return [];
    }
    const url = tenantId
      ? `Dashboard/inboundbystatus/${startDate}/${endDate}?tenantId=${tenantId}`
      : `Dashboard/inboundbystatus/${startDate}/${endDate}`;
    const response = await secureAPI.get(url);
    return response.data;
  };

  const getOutboundSummaryByStatus = async (
    tenantId: number | null = null,
    startDate: string | null,
    endDate: string | null,
    requestType: number | null
  ) => {
    if (!startDate || !endDate) {
      return [];
    }
    const url = tenantId
      ? `Dashboard/outboundbystatus/${startDate}/${endDate}/${requestType}?tenantId=${tenantId}`
      : `Dashboard/outboundbystatus/${startDate}/${endDate}/${requestType}`;
    const response = await secureAPI.get(url);
    return response.data;
  };

  const getOverallWarehousesCapacity = async (
    tenantId: number | null = null
  ) => {
    const url = tenantId
      ? `Dashboard/warehousescapacity?tenantId=${tenantId}`
      : `Dashboard/warehousescapacity`;
    const response = await secureAPI.get(url);
    return response.data;
  };

  const getWarehouseCapacity = async (id: number) => {
    const response = await secureAPI.get(`Dashboard/warehousecapacity/${id}`);
    return response.data;
  };

  const getAnnualUtilization = async (id: number) => {
    const response = await secureAPI.get(
      `Dashboard/annualwarehouseutlization/${id}`
    );
    return response.data;
  };

  return {
    getOrderCount,
    getCustomerCount,
    getGoodsSummary,
    getStorageSummary,
    getExpiringItemsCount,
    getExpiringContracts,
    getNearyExpirySkus,
    getLowStockSkus,
    getInboundSummaryByStatus,
    getOutboundSummaryByStatus,
    getOverallWarehousesCapacity,
    getWarehouseCapacity,
    getAnnualUtilization,
  };
};

export default useDashboardService;
