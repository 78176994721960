import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { t } from "i18next";
import { useNotificationContext } from "../../../context/notificationcontext";

import GridDX from "../../layout/griddx";
import BoxDX from "../../layout/boxdx";
import SelectListDX from "../../controls/selectlistdx";
import TextFieldDX from "../../controls/textfielddx";
import useCityService from "../../../shared/services/cityservice";
import ItemBox from "../../units_components/itembox";
import { InputAdornment } from "@mui/material";
import CheckBoxDX from "../../controls/checkboxdx";
import useWarehouseService from "../../../shared/services/warehouseservice";
import TypeTranslator from "../../../shared/typetranslator";
import useWarehouseAreaService from "../../../shared/services/warehouseareaservice";
import TypographyDX from "../../layout/typographydx";
import ButtonDX from "../../controls/buttondx";
import DataGridDX from "../../layout/datagriddx";
import { Edit, Archive } from "@mui/icons-material";
import {
  GridColDef,
  GridRowParams,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import SubscriptionDetailsModal from "./subscriptiondetailsmodal";
import useCustomerService from "../../../shared/services/customerservice";
import { useAuthContext } from "../../../context/authcontext";
import moment from "moment";
import { API_DATE_FORMAT, DATE_FORMAT } from "../../../shared/globals";
import useSubscriptionPackageService from "../../../shared/services/subscriptionpackageservice";
import useQuotationService from "../../../shared/services/quotationservice";
import AutoCompleteMultiple from "../../controls/autocompletemultiple";
import UploadBoxDX from "../../controls/uploadboxdx";
import DeleteModal from "../../alerts/deleteModal";
import UseIndustryTypeService from "../../../shared/services/industrytypeservice";
import { useTenantContext } from "../../../context/tenantcontext";

const CustomerDetails = (props: any) => {
  const { state } = useLocation();
  const customerId = state?.customerId;
  const {
    setIsDataChanged,
    setSaveRecordfn,
    handleClose,
    isDataChanged,
    setIsSaving,
  } = useOutletContext() as any;
  const { addCustomer, updateCustomer, getCustomerById, deleteCustomerLogo } = useCustomerService();
  const { userData } = useAuthContext();
  const { tenantId } = useTenantContext();
  const { getWarehouses } = useWarehouseService();
  const { getSubscriptionPackages } = useSubscriptionPackageService();
  const { getQuotations } = useQuotationService();
  const { getIndustryTypes } = UseIndustryTypeService();
  const defaultValues = {
    fullname: "",
    phoneNumber: "",
    email: "",
    companyName: "",
    // subscriptions: [
    //   // {
    //   //   customerId: 0,
    //   //   warehouseId: 1,
    //   //   quotationId: 15,
    //   //   subscriptionPackageId: 1,
    //   //   subscribedCbm: 100,
    //   //   startDate: "2024-05-14T15:45:34.791Z",
    //   // },
    // ],
    tenantId : userData.userType === "T" ? userData.tenantId : tenantId,
    customerCode: "",
    pocName: "",
    companyWebsite: "",
    customerTypeId: null,
    vatNumber: "",
    gstNumber: "",
    streetAddress: "",
    cityId: null,
    postalCode: "",
    customerLogo: null,
    logoURL: null,
  };

  const navigate = useNavigate();
  const { setInfo, setError } = useNotificationContext();

  const [customerData, setCustomerData] = useState<any>(defaultValues);
  const [customerSubscriptions, setCustomerSubscriptions] = useState<any>([]);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isFormEditable, setIsFormEditable] = useState(true);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

  const [warehouses, setWarehouses] = useState<any>([]);
  const [warehouseDropdownData, setWarehouseDropdownData] = useState<any>([]);

  const [packages, setPackages] = useState<any>([]);
  const [packageDropdownData, setPackageDropdownData] = useState<any>([]);
  const [quotationDropdownData, setQuotationDropdownData] = useState<any>([]);

  const [subscriptionId, setSubscriptionId] = useState(null);

  const getDropDownData = () => {
    const p1 = userData?.userType === "S" ? getWarehouses(tenantId) : getWarehouses();
    const p2 = getSubscriptionPackages();
    const p3 = getQuotations();
    Promise.all([p1, p2, p3]).then(([warehouses, packages, quotations]) => {
      setWarehouses(warehouses);
      setWarehouseDropdownData(
        warehouses.map((warehouse: any) => ({
          text: warehouse.name,
          value: warehouse.warehouseId,
        }))
      );
      setPackages(packages);
      setPackageDropdownData(
        packages.map((pkg: any) => ({
          text: pkg.name,
          value: pkg.subscriptionPackageId,
        }))
      );
      setQuotationDropdownData(
        quotations.map((quot: any) => ({
          text: quot.quotationNumber,
          value: quot.quotationId,
        }))
      );
    });
  };
  useEffect(() => {
    getDropDownData();
  }, []);

  // Refs for input fields
  const pocNameRef = useRef<HTMLInputElement>(null);
  const pocPhoneRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsDataChanged(true);
    setCustomerData({
      ...customerData,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (!customerData.fullname) {
      newErrors["fullname"] = t("Name is required");
    }
    if (!customerData.phoneNumber) {
      newErrors["phoneNumber"] = t("Phone number is required");
    } else {
      const phonePattern = /^((?:[+?0?0?966]+)(?:\s?\d{2})(?:\s?\d{7}))$/; //Saudi Phone Number Pattern
      if (!phonePattern.test(customerData.phoneNumber)) {
        newErrors["phoneNumber"] = t("Please enter a valid phone number");
      }
    }
    if (!customerData.email) {
      newErrors["email"] = t("Email is required");
    }
    else{
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailPattern.test(customerData.email)) {
          newErrors["email"] = t("Please enter a valid email address");
      }
    }
    if (!customerData.companyName) {
      newErrors["companyName"] = t("Company name is required");
    }
    if (customerData.companyWebsite){
    const websitePattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+)(\/[^\s]*)?$/;
    if (customerData.companyWebsite && !websitePattern.test(customerData.companyWebsite)) {
      newErrors["companyWebsite"] = t("Please enter a valid company website");
    }
    }
    if (customerData.vatNumber) {
        const vatPattern = /^(?:SA)?\d{15}$/;
        if (!vatPattern.test(customerData.vatNumber)) {
            newErrors["vatNumber"] = t("Please enter a valid VAT number");
        }
    }
    if (customerData.gstNumber) {
        const gstPattern = /^(?:SA)?\d{15}$/;
        if (!gstPattern.test(customerData.vatNumber)) {
            newErrors["gstNumber"] = t("Please enter a valid GST number");
        }
    }
    // console.log(customerSubscriptions);
    if (customerSubscriptions.length === 0) {
      setError(t("At least one subscription is required"));
      newErrors["subscriptions"] = t("At least one subscription is required");
    }
    
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  const [industryTypesDropdownData, setIndustryTypesDropdownData] = useState<any>([]);
  const [industryTypes, setIndustryTypes] = useState<any>([]);
  const [customerIndustries, setCustomerIndustries] = useState<any>([]);
  const onSave = (status: any) => {
    if (validateForm()) {
      console.log("customerIndustries", customerIndustries);
      console.log("isDataChanged", isDataChanged)
      if (!isDataChanged) {
        handleClose();
        return;
      }
      setIsSaving(true);
      setIsFormEditable(false);
      // remove id from subscriptions before saving
      const customerSubscriptionsToSave = customerSubscriptions.map(
        (sub: any) => {
          const { id, ...rest } = sub;
          return rest;
        }
      );

      const formData = new FormData();
      for (var key in customerData) {
        if (customerData[key] !== null && customerData[key] !== undefined) formData.append(key, customerData[key]);
      }
      if (!customerId) {
        for (let i = 0; i < industryTypes.length; i++) {
          formData.append(`IndustryTyes[${i}]`, industryTypes[i]?.value);
        }
      } else {
        for (let i = 0; i < customerIndustries.length; i++) {
          if (customerIndustries[i].isDeleted) {
            formData.append(`CustomerIndustries[${i}].isDeleted`, "true");
            formData.append(`CustomerIndustries[${i}].customerIndustryId`, customerIndustries[i].customerIndustryId);
            formData.append(`CustomerIndustries[${i}].customerId`, customerId);
            formData.append(`CustomerIndustries[${i}].industryTypeId`, customerIndustries[i]?.industryTypeId);
            formData.append(`CustomerIndustries[${i}].industryName`, customerIndustries[i].industryName);
          } else {
            formData.append(`CustomerIndustries[${i}].customerIndustryId`, customerIndustries[i].customerIndustryId);
            formData.append(`CustomerIndustries[${i}].industryTypeId`, customerIndustries[i].industryTypeId);
            formData.append(`CustomerIndustries[${i}].customerId`, customerId);
            formData.append(`CustomerIndustries[${i}].industryName`, customerIndustries[i].industryName);
          }
        }
        // start industry types from the last index of customerIndustries and add the new ones
        for (let i = customerIndustries.length; i < industryTypes.length + customerIndustries.length; i++) {
          // check if the industry type is already there in the customerIndustries array and not deleted so no need to add it again
          // check for isDeleted flag as well
          if (!customerIndustries.find((type: any) => type.industryTypeId === industryTypes[i - customerIndustries.length]?.value && !type.isDeleted)) {
            formData.append(`CustomerIndustries[${i}].customerId`, customerId);
            formData.append(`CustomerIndustries[${i}].customerIndustryId`, "0");
            formData.append(`CustomerIndustries[${i}].industryTypeId`, industryTypes[i - customerIndustries.length]?.value);
          }
        
          // formData.append(`CustomerIndustries[${i}].customerId`, customerId);
          // formData.append(`CustomerIndustries[${i}].customerIndustryId`, "0");
          // formData.append(`CustomerIndustries[${i}].industryTypeId`, industryTypes[i - customerIndustries.length]?.value);
        }

      }

      //  append like this to send array of objects (like subscriptions[0] , subscriptions[1] ...)
      for (let i = 0; i < customerSubscriptionsToSave.length; i++) {
        for (var subKey in customerSubscriptionsToSave[i]) {
          if (subKey === "startDate") {
            formData.append(
              `Subscriptions[${i}].${subKey}`,
              moment(customerSubscriptionsToSave[i][subKey]).format(API_DATE_FORMAT)
            );
          } else{
            if (customerSubscriptionsToSave[i][subKey])
              formData.append(
                `Subscriptions[${i}].${subKey}`,
                customerSubscriptionsToSave[i][subKey]
              );
          }
        }
      }


      const operation = customerId
        ? updateCustomer(formData)
        : addCustomer(formData);
      operation
        // addCustomer({ ...customerData, subscriptions: customerSubscriptionsToSave })
        .then(() => {
          customerId
            ? setInfo(t("Customer updated successfully"))
            : setInfo(t("Customer added successfully"));
          handleClose();
        })
        .catch((error: any) => {
          setError(error);
          console.log(error);
        })
        .finally(() => {
          setIsSaving(false);
          setIsFormEditable(true);
        });
    }
  };

  const getData = () => {
    setIsLoading(true);
    const p1 = customerId ? getCustomerById(customerId) : Promise.resolve(null);
    const p2 = getIndustryTypes();
    Promise.all([p1, p2]).then(([customer, industryTypes]) => {
      if (customer) {
        const { subscriptions, customerIndustries, ...rest } = customer;
        setCustomerIndustries(customerIndustries);
        setIndustryTypes(customerIndustries.map((type: any) => ({ text: type.industryName, value: type.industryTypeId })));

        setCustomerSubscriptions(
          subscriptions.map((sub: any) => ({
            ...sub,
            // adding a random id to each subscription for the grid  and to help in edit in deleting
            id: Math.floor(Math.random() * 1000),
          }))
        );
        setCustomerData({ ...defaultValues, ...rest });
      }
      setIndustryTypesDropdownData(
        industryTypes.map((type: any) => ({
          text: type.name,
          value: type.industryTypeId,
        }))
      );
    }
    ).catch((err) => setError(err))
      .finally(() => setIsLoading(false));

    // if (customerId) {
    //   setIsLoading(true);
    //   getCustomerById(customerId)
    //     .then((response) => {
    //       const { subscriptions, ...rest } = response;
    //       setCustomerSubscriptions(
    //         subscriptions.map((sub: any) => ({
    //           ...sub,
    //           // adding a random id to each subscription for the grid  and to help in edit in deleting
    //           id: Math.floor(Math.random() * 1000),
    //         }))
    //       );
    //       setCustomerData(rest);
    //     })
    //     .catch((err) => setError(err))
    //     .finally(() => setIsLoading(false));
    // }
  };

  useEffect(() => {
    getData();
    console.log(userData);
  }, []);
  useEffect(() => {
    setSaveRecordfn(() => onSave);
  }, [customerData, customerSubscriptions, industryTypes]);

  const subscriptionColumns: GridColDef[] = [
    {
      field: "subscriptionPackageId",
      headerName: `${t("Package Name")}`,
      flex: 1,
      valueFormatter: (params) => {
        return packages.find(
          (pkg: any) => pkg.subscriptionPackageId === params.value
        )?.name;
      },
    },
    {
      field: "warehouseId",
      headerName: `${t("Warehouse")}`,
      flex: 1,
      valueFormatter: (params) => {
        return warehouses.find((w: any) => w.warehouseId === params.value)
          ?.name;
      },
    },
    {
      field: "subscribedCbm",
      headerName: `${t("Subscribed CBM")}`,
      flex: 1,
    },
    {
      field: "startDate",
      headerName: `${t("Start Date")}`,
      flex: 1,
      valueFormatter: (params) => moment(params.value).format(DATE_FORMAT),
    },
    {
      headerName: t("Actions"),
      field: "actions",
      type: "actions",
      getActions: (params: GridRowParams) => {
        let actionsArray = [];

        actionsArray.push(
          <GridActionsCellItem
            label={t("Edit")}
            showInMenu
            onClick={() => {
              onEdit(params.row);
            }}
            icon={<Edit />}
          />
        );

        actionsArray.push(
          <GridActionsCellItem
            label={t("Archive")}
            showInMenu
            onClick={() => {
              onArchive(params.row);
            }}
            icon={<Archive />}
          />
        );

        return actionsArray;
      },

      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  const toCreate = () => {
    setShowSubscriptionModal(true);
  };
  const onEdit = (data: any) => {
    if (data.row.id) {
      setSubscriptionId(data.row.id);
    }

    setShowSubscriptionModal(true);
  };
  const onArchive = (row: any) => {
    if (row.id) {
      setCustomerSubscriptions((prev: any) =>
        prev.filter((sub: any) => sub.id !== row.id)
      );
    }
  };
  const handleCLoseSubscriptionModal = () => {
    setShowSubscriptionModal(false);
    setSubscriptionId(null);
  };

  const handleIndustryTypesChange = (value: any) => {
    if (customerData.customerId) {
      // check if industry type was there before and now removed so just add a isDeleted flag but before that check if it has customerIndustryId or not
      let updatedCustomerIndustries = [...customerIndustries];
      for (let i = 0; i < updatedCustomerIndustries.length; i++) {
        if (!value.find((type: any) => type?.value === updatedCustomerIndustries[i].industryTypeId)) {
          if (updatedCustomerIndustries[i].customerIndustryId) {
            updatedCustomerIndustries[i].isDeleted = true;
          }
        }
      }
      setCustomerIndustries(updatedCustomerIndustries);
    }


    setIndustryTypes(value);
    setIsDataChanged(true);


  };

  const handleImageChange = async (newImage: any, index: number) => {
    setIsDataChanged(true);
    if (newImage !== undefined) {
      if (newImage === null && customerData.logoURL) {
        setDeleteImgModal(true)
      }

      setCustomerData(() => ({
        ...customerData,
        customerLogo: newImage,
      })
      )

    }
  };
  const [deleteImgModal, setDeleteImgModal] = useState(false)
  const confirmDelete = () => {
    setIsLoading(true);
    deleteCustomerLogo(customerData.customerId)
      .then((res) => {
        setInfo(t("Picture deleted successfully"));
        setDeleteImgModal(false)
        setCustomerData({ ...customerData, customerLogo: null, logoURL: null })
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));

  };

  return (
    <BoxDX sx={{ width: "100%" }}>
      {deleteImgModal && (
        <DeleteModal
          open={deleteImgModal}
          deleteLabel={t("Delete")}
          name={'customerLogo'}
          onDeleteClick={confirmDelete}
          onCancelClick={() => setDeleteImgModal(false)}
        />
      )}
      {showSubscriptionModal && (
        <SubscriptionDetailsModal
          handleClose={handleCLoseSubscriptionModal}
          setCustomerSubscriptions={setCustomerSubscriptions}
          customerSubscriptions={customerSubscriptions}
          packages={packages}
          warehouseDropdownData={warehouseDropdownData}
          packageDropdownData={packageDropdownData}
          quotationDropdownData={quotationDropdownData}
          subscriptionId={subscriptionId}
        />
      )}
      <ItemBox>
        <GridDX container columnSpacing={1} rowSpacing={2}>
          <GridDX item xs={12}>
            <TypographyDX variant="h6">{t("Company Details")}</TypographyDX>
          </GridDX>
          <GridDX item xs={12} md={6}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Customer Name")}
              name="fullname"
              value={customerData.fullname}
              onChange={handleInputChange}
              error={errors["fullname"]}
            />
          </GridDX>
          <GridDX item xs={12} md={6}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Customer Code")}
              name="customerCode"
              value={customerData.customerCode}
              onChange={handleInputChange}
              error={errors["customerCode"]}
            />
          </GridDX>

          <GridDX item xs={12} md={6}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Company Name")}
              name="companyName"
              value={customerData.companyName}
              onChange={handleInputChange}
              error={errors["companyName"]}
            />
          </GridDX>
          <GridDX item xs={12} md={6}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Company Website")}
              name="companyWebsite"
              value={customerData.companyWebsite}
              onChange={handleInputChange}
              error={errors["companyWebsite"]}
            />
          </GridDX>

          <GridDX item xs={12} md={6}>
            <SelectListDX
              disabled={!isFormEditable}
              label={t("Type of Customer")}
              name="customerTypeId"
              value={customerData.customerTypeId}
              onChange={handleInputChange}
              items={[
                { text: "Fulfilment", value: 1 },
                { text: "Non-Fulfilment", value: 2 }, 
                { text: "Fulfilment and non-Fulfilment", value: 3 },
              ]}
              InputLabelProps={{
                shrink: customerData.customerTypeId !== null, // Set to true if there's a value just to handle label position
              }}
            />
          </GridDX>
          <GridDX item xs={12} md={6}>
            <AutoCompleteMultiple
              disabled={!isFormEditable}
              label={t("Select Industry")}
              name="industryTyes"
              value={industryTypes}
              onChange={handleIndustryTypesChange}
              list={industryTypesDropdownData}
            />
          </GridDX>


          <GridDX item xs={12} md={6}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Contact Name")}
              name="pocName"
              value={customerData.pocName}
              onChange={handleInputChange}
              error={errors["pocName"]}
            />
          </GridDX>
          <GridDX item xs={12} md={6}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Contact Number")}
              name="phoneNumber"
              value={customerData.phoneNumber}
              onChange={handleInputChange}
              error={errors["phoneNumber"]}
              type="number"
              // Disable spin buttons for number input
              className="noSpinButtons"
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Email")}
              name="email"
              value={customerData.email}
              onChange={handleInputChange}
              error={errors["email"]}
            />
          </GridDX>


          {/* vatNumber: "", */}
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("VAT Number")}
              name="vatNumber"
              value={customerData.vatNumber}
              onChange={handleInputChange}
              error={errors["vatNumber"]}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("GST Number")}
              name="gstNumber"
              value={customerData.gstNumber}
              onChange={handleInputChange}
              error={errors["gstNumber"]}
            />
          </GridDX>
          <GridDX item xs={12} sx={{ overflowX: "auto" }}>
            <div style={{ display: "flex", paddingBottom: "10px" }}>
              <UploadBoxDX
                sx={{ width: "270px", height: "250px" }}
                image={customerData.customerLogo}
                imageUrl={customerData.logoURL} // Pass the image URL to the component
                setImage={(newImage: any) => handleImageChange(newImage, 0)}
              />
            </div>
          </GridDX>
          <GridDX item xs={12}>
            <TypographyDX variant="h6">{t("Address")}</TypographyDX>
          </GridDX>
          <GridDX item xs={12} md={6}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Street Address")}
              name="streetAddress"
              value={customerData.streetAddress}
              onChange={handleInputChange}
              error={errors["streetAddress"]}
            />
          </GridDX>
          <GridDX item xs={12} md={6}>
            <SelectListDX
              disabled={!isFormEditable}
              label={t("City")}
              name="cityId"
              value={customerData.cityId}
              onChange={handleInputChange}
              options={[]}
            />
          </GridDX>
          <GridDX item xs={12} md={6}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Postal Code")}
              name="postalCode"
              value={customerData.postalCode}
              onChange={handleInputChange}
              error={errors["postalCode"]}
            />
          </GridDX>



        </GridDX>
      </ItemBox>
      <ItemBox>
        <BoxDX sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <TypographyDX variant="h5" sx={{}}>
            {t("Subscriptions")}
          </TypographyDX>
          <ButtonDX
            variant="contained"
            color="primary"
            onClick={toCreate}
            disabled={!isFormEditable}
          >
            {t("Add Subscription")}
          </ButtonDX>
        </BoxDX>

        <DataGridDX
          getRowId={(row: any) => row.id}
          rows={customerSubscriptions}
          columns={subscriptionColumns}
          loading={isLoading}
          disableRowSelectionOnClick={true}
          checkboxSelection={false}
          onRowClick={onEdit}
        />
      </ItemBox>
    </BoxDX>
  );
};

export default CustomerDetails;
