import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import moment from "moment";
import ListPageDX from "../../components/business/listpagedx";
import { useNotificationContext } from "../../context/notificationcontext";
import TypeTranslator from "../../shared/typetranslator";
import useTenantService from "../../shared/services/tenantservice";

const Tenants = () => {
  const {
    getStorageTypeValue,
    getLeadRequestTypeValue,
    getLeadRequestStatusValue,
    leadRequestStatuses,
    getRentalPeriodValue,
  } = TypeTranslator();

  const { setAddRecordfn, setAddRecordLabel } = useOutletContext() as any;
  const { t, i18n } = useTranslation();
  const { setInfo, setError } = useNotificationContext();
  const { getTenants,archiveTenant } = useTenantService();
  const [dataFromApi, setDataFromApi] = useState<any>([]);
  const [rows, setRows] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: `${t("Name")}`,
      flex: 1,
    },
    {
      field: "phoneNumber",
      headerName: `${t("Phone Number")}`,
      flex: 1,
      headerAlign: "left",
      // it has to be a number to be able to sort
      type: "number",
      align: "left"
    },
    {
      field: "email",
      headerName: `${t("Email")}`,
      flex: 1,
    },
    {
      field: "urlPrefix",
      headerName: `${t("URL Prefix")}`,
      flex: 1,
    },
  ];

  const getData = () => {
    setIsLoading(true);
    getTenants()
      .then((data) => {
        setRows(data);
        setDataFromApi(data);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    archiveTenant(id)
      .then(() => {
        setInfo(t("Tenant archived successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };
  const onEdit = (data: any) => {
    navigate(`/tenantdetails`, {
      state: {
        tenantId: data?.tenantId,
      },
    });
  };

  const toCreate = () => {
    navigate("/tenantdetails", {
      state: {
        leadId: null,
      },
    });
  };

  const filterByDate = (startDate: Date, endDate: Date) => {
    if (startDate && endDate) {
      const sd = moment(startDate);
      const ed = moment(endDate);
      const newRows = dataFromApi.filter(({ requestDate }: any) => {
        return moment(requestDate).isBetween(sd, ed, undefined, "[]");
      });
      setRows(newRows);
    } else setRows(dataFromApi);
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(dataFromApi);
      return;
    }
    const newRows = dataFromApi.filter(
      ({ fullname, phoneNumber, email, companyName }: any) => {
        return (
          fullname?.toLowerCase().includes(value.toLowerCase()) ||
          phoneNumber?.toLowerCase().includes(value.toLowerCase()) ||
          email?.toLowerCase().includes(value.toLowerCase()) ||
          companyName?.toLowerCase().includes(value.toLowerCase())
        );
      }
    );
    setRows(newRows);
  };

  useEffect(() => {
    setAddRecordfn(() => toCreate);
    setAddRecordLabel(t("Add Tenant"));
    getData();

    return () => {
      setAddRecordfn(() => {});
      setAddRecordLabel(t("Add Record"));
    };
  }, [i18n.language]);
  return (
    <ListPageDX
      listTitle={t("Tenants")}
      name={t("Tenant")}
      rows={rows}
      columns={columns}
      getRowId={(row: any) => row.tenantId}
      isLoading={isLoading}
      onEdit={onEdit}
      editLabel={t("Preview")}
      onDelete={onDelete}
      deleteLabel={t("Archive")}
      setGridFilterCriteria={setGridFilterCriteria}
      exportToPDF={false}
      exportToCSV={false}
      showDatePicker={true}
      filterByDate={filterByDate}
    />
  );
};

export default Tenants;
