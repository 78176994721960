import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ListPageDX from "../business/listpagedx";
import GridDX from "../layout/griddx";
import TypographyDX from "../layout/typographydx";
import { AddOutlined } from "@mui/icons-material";
import { Button, Chip } from "@mui/material";
import { useNotificationContext } from "../../context/notificationcontext";
import TypeTranslator from "../../shared/typetranslator";
import { useAuthContext } from "../../context/authcontext";
import OutboundRequestItemForm from "../units_forms/outboundrequests/outboundrequestitemform";
import OutboundRequestItemBulkForm from "../units_forms/outbound/itembulkform";

const OutboundRequestItems = (props: any) => {
	const { userData } = useAuthContext();
	const {
		requestData,
		setRequestData,
		getData,
		setIsChanged,
		skus,
		skuDropdownData,
		isFormEditable,
		isProcessInitiated,
		getSkuDropDownData,
		isBulk,
		setIsBulk,
		bulkFile,
		setBulkFile,
	} = props;
	const [requestItemId, setRequestItemId] = useState(null);
	const { t, i18n } = useTranslation();
	const [rows, setRows] = useState<any>(requestData.outboundRequestSkus);
	const [items, setItems] = useState<any>(requestData.outboundRequestSkus);

	const [isLoading, setIsLoading] = useState(false);
	const { setInfo } = useNotificationContext();
	const [importForm, setImportForm] = useState(false);
	const { getPackagingTypeValue } = TypeTranslator();

	const columns: GridColDef[] = [
		{
			field: "skuId",
			headerName: t("SKU"),
			flex: 1,
			valueGetter: (params: any) => {
				const sku = skus.find((s: any) => s.skuId === params.row.skuId);
				return sku ? sku.name : "";
			},
		},
		{ field: "quantity", headerName: t("Quantity"), flex: 1 },
		{
			field: "packagingTypeId",
			headerName: t("Packaging Type"),
			flex: 1,
			valueGetter: (params: any) => {
				return getPackagingTypeValue(params.row.packagingTypeId);
			},
		},
	];

	const onDelete = (id: number) => {
		setRequestData((prev: any) => {
			const deletedItem = prev.outboundRequestSkus.find(
				(item: any) => item.item_id === id
			);
			const updatedItems = prev.outboundRequestSkus.filter(
				(item: any) => item.item_id !== id
			);
			const updatedData = {
				...prev,
				outboundRequestSkus: [
					...updatedItems,
					{ ...deletedItem, isDeleted: true },
				],
			};
			return updatedData;
		});
		setIsChanged(true);
		setInfo("Item deleted successfully");
	};

	const [showForm, setShowForm] = useState(false);
	const onEdit = (data: any) => {
		setRequestItemId(data.item_id);
		setShowForm(true);
	};

	const toCreate = () => {
		setShowForm(true);
	};

	const onClose = (refreshPage: boolean = false) => {
		setShowForm(false);
		setRequestItemId(null);

		if (refreshPage) getData();
	};

	const setGridFilterCriteria = (value: string) => {
		if (!value.trim()) {
			setRows(items);
			return;
		}
		const newRows = items.filter(
			({ quantity, skuId, packagingTypeId }: any) => {
				return (
					quantity.toString().toLowerCase().includes(value.toLowerCase()) ||
					getPackagingTypeValue(packagingTypeId)
						.toLowerCase()
						.includes(value.toLowerCase()) ||
					skus
						.find((s: any) => s.skuId === skuId)
						.name.toLowerCase()
						.includes(value.toLowerCase())
				);
			}
		);
		setRows(newRows);
	};

	useEffect(() => {
		const filteredItems = requestData?.outboundRequestSkus?.filter(
			(item: any) => !item.isDeleted
		);
		setRows(filteredItems);
		setItems(filteredItems);
	}, [requestData.outboundRequestSkus]);

	const onCloseItemBulkForm = (refresh: boolean) => {
		setImportForm(false);
	};

	return (
		<>
			{showForm && (
				<OutboundRequestItemForm
					requestData={requestData}
					requestItemId={requestItemId}
					setRequestData={setRequestData}
					handleClose={onClose}
					setIsChanged={setIsChanged}
					skus={skus}
					skuDropdownData={skuDropdownData}
					getSkuDropDownData={getSkuDropDownData}
				/>
			)}

			{importForm && (
				<OutboundRequestItemBulkForm
					open={importForm}
					requestData={requestData}
					setRequestData={setRequestData}
					setIsChanged={setIsChanged}
					skus={skus}
					handleClose={onCloseItemBulkForm}
					isBulk={isBulk}
					setIsBulk={setIsBulk}
					setBulkFile={setBulkFile}
				/>
			)}

			<GridDX container xs={12}>
				<GridDX item xs={12} justifyContent="space-between">
					<TypographyDX variant="h5" fontWeight="bold">
						{t("Items")}
					</TypographyDX>
					{(isFormEditable ||
						(userData.userType == "S" &&
							requestData.status == 3 &&
							isProcessInitiated)) &&
						!bulkFile && (
							<Button
								variant="contained"
								color="secondary"
								sx={{ color: "#fff", wordSpacing: 4 }}
								onClick={toCreate}
							>
								<AddOutlined /> {t("Add Item")}
							</Button>
						)}

					{bulkFile && (
						<Chip
							label={bulkFile.name}
							color="primary"
							onDelete={() => setBulkFile(null)}
						/>
					)}
				</GridDX>
				{!bulkFile && (
					<GridDX item xs={12} sx={{ mb: 2 }}>
						<ListPageDX
							listTitle={t("Item")}
							name={t("Item")}
							rows={rows}
							columns={columns}
							getRowId={(row: any) => row.item_id}
							isLoading={isLoading}
							// toCreate={toCreate}
							editLabel={t("Edit")}
							// enabled (for admin only) if the request is approved or in draft
							onEdit={
								(isFormEditable ||
									(userData.userType == "S" &&
										requestData.status == 3 &&
										isProcessInitiated) ||
									requestData.status == 5) &&
								onEdit
							}
							onDelete={
								(isFormEditable ||
									(userData.userType == "S" &&
										requestData.status == 3 &&
										isProcessInitiated)) &&
								onDelete
							}
							exportToPDF={false}
							exportToCSV={false}
							showDatePicker={false}
							showSelect={false}
							searchAndFilter={true}
							exportDropdown={false}
							showImportButton={requestData.status === 1}
							onImport={() => setImportForm(true)}
							importTemplateLink={
								"../assets/templates/UnitsOrderItemsTemplate.xlsx"
							}
							setGridFilterCriteria={setGridFilterCriteria}
						/>
					</GridDX>
				)}
			</GridDX>
		</>
	);
};

export default OutboundRequestItems;
