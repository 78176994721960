import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	GridActionsCellItem,
	GridColDef,
	GridRowParams,
} from "@mui/x-data-grid";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

import GridDX from "../layout/griddx";
import ButtonDX from "../controls/buttondx";
import DataGridDX from "../layout/datagriddx";
import GridSearchFilterTextbox from "./gridsearchfiltertextbox";
import DeleteModal from "../alerts/deleteModal";
import { useGridApiRef } from "@mui/x-data-grid";
import * as XLSX from "xlsx";
// import "../../assets/fonts/arabic-font-normal.js"; // Import the JavaScript file with the custom font
import unitsLogo from "../../assets/customer_logo.png";
import tenantDefaultLogo from "../../assets/tenant_logo.png";

import {
	Archive,
	Edit,
	Delete,
	Preview,
	Print,
	SystemUpdateAltOutlined,
} from "@mui/icons-material";
import SelectListDX from "../controls/selectlistdx";
import ActionDropDownMenuDx from "../controls/dropdownmenudx";
import UnitsRangePicker from "../units_controls/units_rangepicker";
import useTenantService from "../../shared/services/tenantservice";
import { useAuthContext } from "../../context/authcontext";
import { toBase64 } from "../../shared/globals";
/**
 * List Page Component
 * @param {any[]} rows data rows
 * @param {GridColDef[]} columns data columns
 * @param {Function} getRowId function to get unique row ID
 * @param {boolean} isLoading boolean to track data fetching or another process
 * @param {Function} toCreate function to create data element
 * @param {Function} onEdit function to edit data element
 * @param {Function} onDelete function to delete data element
 */
const ListPageDX = (props: any) => {
	const customerName = "CustomerName";
	const apiRef = useGridApiRef();
	const { userData } = useAuthContext();
	const { t, i18n } = useTranslation();
	const languageIsEn = i18n.language === "en";
	const {
		toCreate,
		onEdit,
		onDelete,
		editLabel,
		getRowId,
		isLoading,
		listTitle,
		createLabel,
		exportToPDF,
		exportToCSV,
		searchAndFilter,
		deleteLabel,
		checkboxSelection,
		rowClick = true,
		customActions,
		name,
		customFilters,
		filterByStatus,
		filterByType,
		hideColumnsOption,
		moreActions,
		sendEmail,
		exportPDFLabel,
		rowsPerPage,
		showDatePicker,
		filterByDate,
		showSelect,
		exportDropdown = true,
		onPrint,
		showImportButton,
		onImport,
		importTemplateLink,
		buildActions,
		rowSelectionModel,
		onRowSelectionModelChange,
		bulkActions,
	} = props;
	const { getTenantById } = useTenantService();

	const [deleteModal, setDeleteModal] = useState({ open: false, id: null });
	const [rows, setRows] = useState<any>([]);
	const [columns, setColumns] = useState<any>([]);
	const [isLoadingData, setIsLoadingData] = useState(false);

	const createBtn = toCreate || false;
	const exportPDFBtn = exportToPDF != false;
	const exportCSVBtn = exportToCSV != false;
	const searchBar = searchAndFilter != false;
	const emailBtn = sendEmail || false;

	useEffect(() => {
		setRows(props.rows);
		setColumns(props.columns);
	}, [props.rows, props.columns]);

	const getTenantLogo = async () => {
		setIsLoadingData(true);
		return getTenantById(userData?.tenantId)
			.then((res) => {
				return res?.logoURL;
			})
			.catch((err) => console.log(err))
			.finally(() => setIsLoadingData(false));
	};

	// actions for table
	const buildColActions = (params: GridRowParams) => {
		let actionsArray = [];

		if (onEdit)
			actionsArray.push(
				<GridActionsCellItem
					label={editLabel ? editLabel : t("Edit")}
					showInMenu
					onClick={() => {
						onEdit(params.row);
					}}
					icon={
						editLabel == t("Preview") || editLabel == t("View") ? (
							<Preview />
						) : (
							<Edit />
						)
					}
				/>
			);

		if (onDelete)
			actionsArray.push(
				<GridActionsCellItem
					label={deleteLabel ? deleteLabel : t("Delete")}
					showInMenu
					onClick={() => {
						handleDeleteModalOpen(params.id);
					}}
					icon={deleteLabel === t("Archive") ? <Archive /> : <Delete />}
				/>
			);
		if (onPrint)
			actionsArray.push(
				<GridActionsCellItem
					label={t("Print")}
					showInMenu
					onClick={() => {
						onPrint(params.row);
					}}
					icon={<Print />}
				/>
			);

		if (moreActions) actionsArray = actionsArray.concat(moreActions);

		return actionsArray;
	};

	const fields: GridColDef[] =
		onEdit || onDelete || moreActions || buildActions
			? [
					...columns,
					onEdit || onDelete || moreActions || buildActions
						? {
								headerName: t("Actions"),
								field: "actions",
								type: "actions",
								// width: 50,
								getActions: buildActions ?? buildColActions,
								sortable: false,
								filterable: false,
								disableColumnMenu: true,
						  }
						: {},
			  ]
			: columns;

	const setGridFilterCriteria = (value: string) => {
		if (!value.trim()) {
			setRows(props.rows);
			return;
		}
		const lowercasedValue = value.toLowerCase();
		const newRows = props.rows.filter(({ enName, arName }: any) =>
			languageIsEn
				? enName?.toLowerCase().includes(lowercasedValue)
				: arName?.includes(value)
		);
		setRows(newRows);
	};

	const onRowClick = (params: GridRowParams) => {
		onEdit && rowClick && onEdit(params.row);
	};

	const handleDeleteModalOpen = (id: any) => {
		setDeleteModal({ open: true, id });
	};

	const confirmDelete = () => {
		onDelete(deleteModal.id);
		setDeleteModal({ open: false, id: null });
	};
	// Function to handle PDF export
	const handleExportToPDF = async () => {
		const sortingOrder = apiRef.current.getSortModel();
		const sortedRows = [...rows];

		// Sort the rows based on the sorting order
		sortedRows.sort((rowA, rowB) => {
			for (const { field, sort } of sortingOrder) {
				const column = columns.find(
					(col: { field: string }) => col.field === field
				);
				const valueA =
					typeof rowA[column.field] == "string"
						? rowA[column.field].toLowerCase()
						: rowA[column.field];
				const valueB =
					typeof rowB[column.field] == "string"
						? rowB[column.field].toLowerCase()
						: rowB[column.field];
				// Implement custom sorting logic here if needed
				// You might need to convert values to the correct data type for sorting
				if (sort === "asc") {
					if (valueA < valueB) return -1;
					if (valueA > valueB) return 1;
				} else {
					if (valueA < valueB) return 1;
					if (valueA > valueB) return -1;
				}
			}
			return 0;
		});
		// Create a new jsPDF instance
		const doc = new jsPDF({
			orientation: "landscape",
			unit: "px",
			format: "a4",
		});
		// Define the columns and rows for the PDF table
		let pdf_column = columns.map((col: GridColDef) => col.headerName);
		let pdf_rows = sortedRows.map((row: any) =>
			columns.map((col: GridColDef) =>
				row[col.field] == null
					? ""
					: typeof row[col.field] !== "object"
					? row[col.field]
					: languageIsEn
					? row[col.field].enName || row[col.field].enFullName
					: row[col.field].arName || row[col.field].arFullName
			)
		);
		// changing alignment of data fields for arabic pdf

		if (!languageIsEn) {
			// if language is arabic
			pdf_column = pdf_column.reverse();
		}

		const totalPages = Math.ceil(pdf_rows.length / 15); // You might need to adjust the number 20 to control the number of rows per page.
		doc.setFont("arabic-font", "normal");

		for (let page = 1; page <= totalPages; page++) {
			if (page > 1) {
				doc.addPage();
			}

			// Define the rows for the current page
			const start = (page - 1) * 15;
			const end = page * 15;
			let currentPageRows = pdf_rows.slice(start, end);
			if (!languageIsEn) {
				// if language is arabic
				currentPageRows = currentPageRows.map((e: any[]) => e.reverse());
			}

			// Set the header image at the top left
			if (userData?.userType !== "S") {
				const tenantLogoPath = await getTenantLogo();
				let tenantLogo: any = "";
				if (tenantLogoPath) {
					const imageData = await fetch(tenantLogoPath);
					const imageBlob = await imageData.blob();
					tenantLogo = await toBase64(imageBlob);
				}
				const logoToAdd = tenantLogoPath ? tenantLogo : tenantDefaultLogo;
				doc.addImage(logoToAdd, "JPEG", 5, 1, 100, 60); // Adjust the image dimensions and position as needed
			} else doc.addImage(unitsLogo, "JPEG", 5, 1, 100, 60); // Adjust the image dimensions and position as needed
			// Set the header text (heading for the table)
			const tableHeading = listTitle || "";
			const headingX = !languageIsEn ? doc.internal.pageSize.width - 10 : 10;
			const headingY = 60; // Adjust the vertical position as needed
			doc.setFontSize(20); // Adjust font size as needed
			doc.text(
				tableHeading,
				headingX,
				headingY,
				undefined,
				!languageIsEn ? "right" : "left"
			);

			// Add a table to the PDF document using jspdf-autotable
			autoTable(doc, {
				head: [pdf_column],
				body: currentPageRows,
				styles: {
					font: !languageIsEn ? "arabic-font" : undefined,
					halign: !languageIsEn ? "right" : undefined,
					cellWidth: "wrap",
				},
				columnStyles: {},
				margin: { top: 70, left: 10, right: 10 },
				horizontalPageBreak: true,
			});

			// Draw a horizontal line
			doc.setLineWidth(0.5); // Adjust line width as needed
			doc.line(
				10,
				doc.internal.pageSize.height - 20,
				doc.internal.pageSize.width - 10,
				doc.internal.pageSize.height - 20
			);

			// Set the footer text (left side: "Customer Name " and right side: printed date)
			const footerLeftText = userData?.fullName;
			const footerRightText = new Date().toLocaleDateString();
			const footerXLeft = 10;
			const footerXRight = doc.internal.pageSize.width - 10;
			const footerY = doc.internal.pageSize.height - 10;
			doc.setFontSize(10); // Adjust font size as needed
			doc.text(footerLeftText, footerXLeft, footerY);
			doc.text(footerRightText, footerXRight, footerY, undefined, "right");
		}

		//   // To open the PDF in a new tab:
		// doc.output("dataurlnewwindow");
		//   // To save the PDF as a file:
		doc.save(`${customerName}_${listTitle}.pdf`);
	};

	const handleExportToExcel = () => {
		if (apiRef.current) {
			const rowIds = apiRef.current.getAllRowIds();
			const rows = rowIds.map((id) => apiRef.current.getRow(id));
			const columns = apiRef.current
				.getAllColumns()
				.filter((col) => col.field !== undefined && col.field !== "actions");

			const sortingOrder = apiRef.current.getSortModel();
			const sortedRows = [...rows];

			// Sort the rows based on the sorting order
			sortedRows.sort((rowA, rowB) => {
				for (const { field, sort } of sortingOrder) {
					const column = columns.find(
						(col: { field: string }) => col.field === field
					);

					const valueA =
						//@ts-ignore
						typeof rowA[column.field] == "string"
							? //@ts-ignore
							  rowA[column.field].toLowerCase()
							: //@ts-ignore
							  rowA[column.field];
					const valueB =
						//@ts-ignore
						typeof rowB[column.field] == "string"
							? //@ts-ignore
							  rowB[column.field].toLowerCase()
							: //@ts-ignore
							  rowB[column.field];

					// Implement custom sorting logic here if needed
					// You might need to convert values to the correct data type for sorting
					if (sort === "asc") {
						if (valueA < valueB) return -1;
						if (valueA > valueB) return 1;
					} else {
						if (valueA < valueB) return 1;
						if (valueA > valueB) return -1;
					}
				}
				return 0;
			});

			// Create an array of data
			const data = sortedRows.map((row) =>
				columns.map((col) =>
					row[col.field] == null
						? ""
						: typeof row[col.field] !== "object"
						? row[col.field]
						: languageIsEn
						? row[col.field]?.enName || row[col.field].enFullName
						: row[col.field]?.arName || row[col.field].arFullName
				)
			);

			// Adjust the column order for Arabic language
			// if (!languageIsEn) {
			//   columns.reverse(); // Reverse the column order
			//   data.forEach((row) => {
			//     row.reverse(); // Reverse the order of values in each row
			//   });
			// }

			// Create a worksheet
			const ws = XLSX.utils.aoa_to_sheet([
				columns.map((col) => col.headerName),
				...data,
			]);

			// Create a workbook
			const wb = XLSX.utils.book_new();
			if (!languageIsEn) {
				wb.Workbook = { Views: [{ RTL: true }] };
			}

			XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

			// Save the workbook as an Excel file with .xlsx extension
			XLSX.writeFile(wb, `${customerName}_${listTitle}.xlsx`);
		}
	};

	const downloadTemplate = () => {
		const link = document.createElement("a");
		link.href = importTemplateLink;
		const fileExtension =
			importTemplateLink.split(".").pop()?.split(/[?#]/)[0] || "csv";
		link.setAttribute("download", `UNITs${listTitle}Template.${fileExtension}`);
		document.body.appendChild(link);
		link.click();
	};

	return (
		<GridDX
			container
			sx={{ my: 1 }}
			alignItems="center"
			columnSpacing={1}
			rowSpacing={2}
			justifyContent={"space-between"}
		>
			{deleteModal.open && (
				<DeleteModal
					open={deleteModal.open}
					deleteLabel={deleteLabel}
					name={name}
					onDeleteClick={confirmDelete}
					onCancelClick={() => setDeleteModal({ open: false, id: null })}
				/>
			)}
			{/* {createBtn && (
        <GridDX item xs={12} md={!exportPDFBtn && !exportCSVBtn ? 3 : 2}>
          <ButtonDX onClick={toCreate}> {createLabel || t("Create")}</ButtonDX>
        </GridDX>
      )} */}

			{searchBar && (
				<GridDX item xs={4}>
					<GridSearchFilterTextbox
						placeholder={t("Search")}
						sx={{
							borderRadius: "8px",
							backgroundColor: "#FFFFFF",
							boxShadow: "0px 2px 4px 0px #0000000F",
							border: "1px solid #D0D5DD",
						}}
						setGridFilterCriteria={
							props.setGridFilterCriteria || setGridFilterCriteria
						}
					/>
				</GridDX>
			)}

			{/* {emailBtn && (
        <GridDX item xs={12} md={2}>
          <ButtonDX onClick={() => {}}> {t("Send By Email")}</ButtonDX>
        </GridDX>
      )} */}

			<GridDX
				container
				justifyContent="flex-end"
				alignItems="center"
				columnSpacing={1}
				pt={"16px"}
				xs={12}
				md={8}
			>
				{showDatePicker && (
					<GridDX item xs={4} justifyContent="flex-end">
						<UnitsRangePicker label={t("Date Range")} toFilter={filterByDate} />
					</GridDX>
				)}
				{showSelect && (
					<GridDX item xs={4} md={2}>
						<SelectListDX
							label={t("Select")}
							size="small"
							items={[
								{ value: "1", text: "1" },
								{ value: "2", text: "2" },
								{ value: "3", text: "3" },
								{ value: "4", text: "4" },
							]}
							sx={{
								borderRadius: "8px",
								boxShadow: "0px 2px 4px 0px #0000000F",
								height: "40px",
								"& .MuiInputBase-input": {
									padding: "8px 10px  !important",
									textAlign: "center",
								},
							}}
						/>
					</GridDX>
				)}
				{bulkActions && (
					<GridDX item sx={{ alignItems: "center" }}>
						{bulkActions}
					</GridDX>
				)}
				{showImportButton && (
					<GridDX item sx={{ alignItems: "center" }}>
						{/* <ButtonDX
              startIcon={<SystemUpdateAltOutlined />}
              onClick={onImport}
            >
              {t("Import")}
            </ButtonDX> */}
						<ActionDropDownMenuDx
							label={t("Import")}
							actions={[
								{ label: t("Import Data"), onClick: onImport },
								{
									label: t("Download Template"),
									onClick: downloadTemplate,
								},
							]}
						/>
					</GridDX>
				)}
				{exportDropdown && (
					<GridDX item sx={{ alignItems: "center" }}>
						<ActionDropDownMenuDx
							label={t("Export")}
							actions={[
								{ label: t("Export to PDF"), onClick: handleExportToPDF },
								{ label: t("Export to CSV"), onClick: handleExportToExcel },
							]}
						/>
					</GridDX>
				)}
			</GridDX>

			{exportCSVBtn && (
				<GridDX
					item
					xs={12}
					sm={3}
					md={3}
					lg={!createBtn && !emailBtn ? 2.5 : 2}
				>
					<ButtonDX onClick={handleExportToExcel}>
						{t("Export to CSV")}
					</ButtonDX>
				</GridDX>
			)}

			{exportPDFBtn && (
				<GridDX
					item
					xs={12}
					sm={3}
					md={3}
					lg={!createBtn && !emailBtn ? 2.5 : 2}
				>
					<ButtonDX onClick={handleExportToPDF}>
						{t(exportPDFLabel || "Export to PDF")}
					</ButtonDX>
				</GridDX>
			)}

			<GridDX item xs={12}>
				<DataGridDX
					apiRef={apiRef}
					getRowId={getRowId}
					rows={rows}
					columns={fields}
					loading={isLoading}
					checkboxSelection={checkboxSelection || false}
					onRowClick={onRowClick}
					disableRowSelectionOnClick={true}
					customActions={customActions}
					customFilters={customFilters}
					filterByStatus={filterByStatus}
					filterByType={filterByType}
					hideColumnsOption={hideColumnsOption}
					rowsPerPage={rowsPerPage}
					rowSelectionModel={rowSelectionModel}
					onRowSelectionModelChange={onRowSelectionModelChange}
				/>
			</GridDX>
		</GridDX>
	);
};

export default ListPageDX;
