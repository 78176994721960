import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CircularProgress, Typography } from "@mui/material";

import { confirmEmail } from "../../shared/services/authservice";

import GridDX from "../../components/layout/griddx";
import appLogo from "../../assets/app_logo.png";

const ConfirmEmail = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isConfirmed, setIsConfirmed] = useState(false);

  useEffect(() => {
    if (searchParams && searchParams.size === 2) {
      const userId = searchParams.get("userId");
      const code = searchParams.get("code");

      confirmEmail({ userId, confirmCode: code })
        .then(() => setIsConfirmed(true))
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    } else navigate("/");
  }, [searchParams]);

  useEffect(() => {
    if (isConfirmed) {
      // Redirect after 5 seconds
      const timer = setTimeout(() => {
        navigate("/");
      }, 5000);

      return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }
  }, [isConfirmed]);

  const showError = () => {
    return (
      <>
        <Typography fontSize={24} fontWeight={"bold"} sx={{ my: 2 }}>
          Error Confirming Email
        </Typography>
        <br />
        <Typography fontSize={18}>{error}</Typography>
      </>
    );
  };

  const showConfirmation = () => {
    return (
      <>
        <Typography fontSize={24} fontWeight={"bold"} sx={{ my: 2 }}>
          Email Confirmed.
        </Typography>
        <Typography fontSize={18} sx={{ my: 2 }}>
          Redirecting to Login Page ...
        </Typography>
        <CircularProgress />
      </>
    );
  };

  return (
    <GridDX
      container
      sx={{
        overflow: "hidden",
        alignContent: "flex-start",
      }}
    >
      <GridDX item xs={12} justifyContent="center">
        <img src={appLogo} height={125} alt="UNITS" />
      </GridDX>

      <GridDX
        item
        xs={12}
        sx={{ alignItems: "center", flexDirection: "column", py: 4 }}
      >
        {isLoading && (
          <>
            <Typography fontSize={24} fontWeight={"bold"} sx={{ my: 2 }}>
              Confirming Email ....
            </Typography>
            <CircularProgress />
          </>
        )}
        {error && showError()}
        {isConfirmed && showConfirmation()}
      </GridDX>
    </GridDX>
  );
};

export default ConfirmEmail;
