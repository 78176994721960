import { PieChart, pieArcLabelClasses } from "@mui/x-charts";
import { useTranslation } from "react-i18next";
import TypographyDX from "../layout/typographydx";

const PieChartDx = (props: any) => {
  const { t, i18n } = useTranslation();
  const { data, height } = props;
  const languageIsEn = i18n.language === "en";

  // Add default data check
  const hasData = data && Array.isArray(data) && data.length > 0;

  return (
    <>
      {hasData ? (
        <PieChart
          series={[
            {
              arcLabel: (item) => `${item.value}`,
              data: data,
              innerRadius: 60,           // Reduced from 130
              outerRadius: 100,          // Increased from 80
              paddingAngle: 0,           // Add padding between segments
              cornerRadius: 2,
              highlightScope: { faded: 'global', highlighted: 'item' }, // Add highlight effect
              arcLabelMinAngle: 45,
            },
          ]}
          slotProps={{
            legend: {
              hidden: true,
            },
          }}
          tooltip={{ 
            trigger: "item" 
          }}
          margin={{ top: 20, bottom: 20, left: 20, right: 20 }} // Adjusted margins
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: "white",
              fontWeight: "bold",
            },
            "--ChartsLegend-rootOffsetX": languageIsEn ? "-20px" : "80px",
            "--ChartsLegend-rootOffsetY": "0px",
            "--ChartsLegend-labelSpacing": languageIsEn ? "8px" : "-40px",
          }}
          height={height || 300}
          width={250}                    // Set explicit width
        />
      ) : (
        <TypographyDX 
          variant="h3" 
          fontWeight="bold" 
          sx={{ color: (theme: any) => theme.palette.primary.main }}
        >
          {t("No data available")}
        </TypographyDX>
      )}
    </>
  );
};

export default PieChartDx;
