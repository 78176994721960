import { CardContent, Typography } from "@mui/material";
// import moment from "moment";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNotificationContext } from "../../context/notificationcontext";
import CardDX from "../layout/carddx";
import GridDX from "../layout/griddx";
import Loading from "../layout/loading";
import PieChartDx from "../business/piechart";
import UnitsFilterRange from "../units_controls/units_filterrange";
import moment from "moment";

const CustomPieChart = (props: any) => {
  const {
    title,
    dataForPieChart,
    height,
    isLoading,
    filterRange = true,
  } = props;
  const { t, i18n } = useTranslation();
  const { setError } = useNotificationContext();

  const [filterFromDate, setFilterFromDate] = useState<any>(null);
  const [filterToDate, setFilterToDate] = useState<any>(null);

  useEffect(() => {
    setFilterFromDate(moment().subtract(7, "days").toDate());
    setFilterToDate(new Date());
    // setFilterFromDate(moment("20140101"));
    // setFilterToDate(moment());
  }, []);

  const handleChangeFromDate = (date: any) => {
    const filterDate = new Date(date);
    if (filterToDate != null && filterDate > filterToDate) {
      setError(t("From date must be less than to date"));
      setFilterFromDate(null);
    } else {
      setFilterFromDate(filterDate);
    }
  };

  const handleChangeToDate = (date: any) => {
    const filterDate = new Date(date);
    if (filterFromDate != null && filterDate < filterFromDate) {
      setError(t("To date must be greater than from date"));
      setFilterToDate(null);
    } else {
      setFilterToDate(filterDate);
    }
  };

  return (
    <CardDX
      sx={{
        width: "100%",
        height: height ? `${height}px` : "100%",
        py: 2,
      }}
    >
      <CardContent
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          p: 0,
        }}
      >
        <GridDX
          container
          columnSpacing={1}
          rowSpacing={2}
          sx={{ 
            width: "100%",
            height: "100%",
          }}
        >
          <GridDX item xs={!filterRange ? 12 : 5} sx={{}}>
            <Typography
              variant="h5"
              component="div"
              sx={{ flexGrow: 1, fontWeight: "bold" }}
            >
              {t(`${title || ""}`)}
            </Typography>
          </GridDX>
          <GridDX
            item
            xs={7}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            {filterRange && (
              <UnitsFilterRange
                filterFromDate={filterFromDate}
                filterToDate={filterToDate}
                handleChangeFromDate={handleChangeFromDate}
                handleChangeToDate={handleChangeToDate}
              />
            )}
          </GridDX>
          <GridDX
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100% - 60px)",
            }}
          >
            {isLoading ? (
              <div style={{ minHeight: "200px", position: "relative" }}>
                <Loading
                  styles={{
                    height: "100%",
                    width: "100%",
                    left: 0,
                    position: "absolute",
                  }}
                />
              </div>
            ) : (
              <PieChartDx data={dataForPieChart} height={height} />
            )}
          </GridDX>
        </GridDX>
      </CardContent>
    </CardDX>
  );
};

export default CustomPieChart;
