import { Dialog, DialogTitle, Typography, DialogContent } from "@mui/material";
import ButtonDX from "../../controls/buttondx";
import LoadingButtonDX from "../../controls/loadingbuttondx";
import GridDX from "../../layout/griddx";
import TypographyDX from "../../layout/typographydx";
import TypeTranslator from "../../../shared/typetranslator";
import { outboundRequestStatusColor } from "../../../shared/globals";
import SelectListDX from "../../controls/selectlistdx";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import useOutboundRequestService from "../../../shared/services/outboundrequestservice";
import { useNotificationContext } from "../../../context/notificationcontext";
import BoxDX from "../../layout/boxdx";
import TextFieldDX from "../../controls/textfielddx";

const OutboundStatusUpdateForm = (props: any) => {
	const { open, handleClose, data } = props;
	const { t, i18n } = useTranslation();
	const { setError, setInfo } = useNotificationContext();
	const { updateOutboundRequestStatusBulk } = useOutboundRequestService();
	const { outboundRequestStatuses } = TypeTranslator();

	const [statusData, setStatusData] = useState<any>(data);
	const [errors, setErrors] = useState("");
	const [isSaving, setIsSaving] = useState(false);

	const usableOutboundRequestStatuses: any = [
		{
			id: 2,
			en_value: "Pending",
			ar_value: "قيد الانتظار",
		},
		{
			id: 6,
			en_value: "Picked",
			ar_value: "اختار",
		},
		{
			id: 8,
			en_value: "Packed",
			ar_value: "معبأ",
		},
		{
			id: 9,
			en_value: "Dispatched",
			ar_value: "أرسلت",
		},
		{
			id: 11,
			en_value: "Completed",
			ar_value: "اكمال",
		},
		{
			id: 14,
			en_value: "Cancelled",
			ar_value: "ألغيت",
		},
	];

	const onSave = async () => {
		const saveData = statusData.map((row: any) => {
			return {
				outboundRequestId: row.id,
				newStatus: row.newStatus,
			};
		});

		setIsSaving(true);
		updateOutboundRequestStatusBulk(saveData)
			.then((res) => {
				if (res === "") {
					setInfo(t("Status updated successfully"));
					handleClose(true);
				} else setErrors(res);
			})
			.catch((err) => setError(err))
			.finally(() => setIsSaving(false));
	};

	const handleValueChange = (event: any, index: number) => {
		setStatusData([
			...statusData.slice(0, index),
			{ ...statusData[index], newStatus: event.target.value },
			...statusData.slice(index + 1),
		]);
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			fullWidth
			maxWidth="md"
			dir={i18n.dir()}
		>
			<DialogTitle>
				<Typography variant="h6">{t("Bulk Update Status")}</Typography>
			</DialogTitle>
			<DialogContent>
				<GridDX container spacing={2}>
					<GridDX item xs={4} justifyContent="center">
						<TypographyDX textAlign="center">{t("Order Number")}</TypographyDX>
					</GridDX>
					<GridDX item xs={4} justifyContent="center">
						<TypographyDX textAlign="center">{t("Old Status")}</TypographyDX>
					</GridDX>
					<GridDX item xs={4} justifyContent="center">
						<TypographyDX textAlign="center">{t("New Status")}</TypographyDX>
					</GridDX>
					{errors.length > 0 ? (
						<GridDX
							item
							xs={12}
							direction="column"
							justifyContent="center"
							alignItems="center"
							sx={{ mx: 2, height: "100%" }}
						>
							<BoxDX
								display="flex"
								sx={{ width: 600, justifyContent: "space-between" }}
							>
								<TextFieldDX
									name="errors"
									label={t("Errors")}
									value={errors}
									multiline
									readOnly
								/>
							</BoxDX>
						</GridDX>
					) : (
						statusData.map((row: any, index: number) => (
							<>
								<GridDX item xs={4} justifyContent="center" alignItems="center">
									<TypographyDX textAlign="center">{row.id}</TypographyDX>
								</GridDX>
								<GridDX item xs={4} justifyContent="center" alignItems="center">
									<div
										style={{
											backgroundColor:
												outboundRequestStatusColor.light[
													outboundRequestStatuses.find(
														(e: any) =>
															e?.en_value === row.statusLabel ||
															e?.ar_value === row.statusLabel
													)?.id
												] || "white",
											color: `${
												outboundRequestStatusColor.regular[
													outboundRequestStatuses.find(
														(e: any) =>
															e?.en_value === row.statusLabel ||
															e?.ar_value === row.statusLabel
													)?.id
												]
											}`,
											padding: "5px",
											margin: "5px",
											borderRadius: "4px",
											width: 100,
											textAlign: "center",
										}}
									>
										{row.statusLabel}
									</div>
								</GridDX>
								<GridDX item xs={4} justifyContent="center">
									<SelectListDX
										items={usableOutboundRequestStatuses.map((item: any) => {
											return {
												text:
													i18n.language === "en"
														? item.en_value
														: item.ar_value,
												value: item.id,
											};
										})}
										value={row.newStatus}
										onChange={(event: any) => handleValueChange(event, index)}
									/>
								</GridDX>
							</>
						))
					)}
				</GridDX>
			</DialogContent>
			<GridDX container justifyContent="flex-end" p={2} gap={2}>
				<ButtonDX onClick={() => handleClose(true)}>{t("Cancel")}</ButtonDX>
				{errors.length === 0 && (
					<LoadingButtonDX loading={isSaving} onClick={onSave}>
						{t("Save")}
					</LoadingButtonDX>
				)}
			</GridDX>
		</Dialog>
	);
};

export default OutboundStatusUpdateForm;
