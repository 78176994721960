import { GridColDef } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext, useNavigate } from "react-router-dom";
import ListPageDX from "../../components/business/listpagedx";
import { useNotificationContext } from "../../context/notificationcontext";
import useSkuCategoryService from "../../shared/services/skucategoryservice";

const SKUCategory = (props: any) => {
  const { t, i18n } = useTranslation();
  const { setAddRecordfn, setAddRecordLabel } = useOutletContext() as any;
  const { setInfo, setError } = useNotificationContext();
  const { getSkuCategories, archiveSkuCategory } = useSkuCategoryService();

  const [rows, setRows] = useState<any>([]);
  const [dataFromApi, setDataFromApi] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: `${t("Name")}`,
      minWidth: 200,
    },
    {
      field: "description",
      headerName: `${t("Description")}`,
      flex: 1,
    },
  ];

  const getData = () => {
    setIsLoading(true);
    getSkuCategories()
      .then((data) => {
        setDataFromApi(data);
        setRows(data);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const toCreate = () => {
    navigate("/skucategorydetails", {
      state: {
        packageData: null,
      },
    });
  };
  const onEdit = (data: any) => {
    navigate(`/skucategorydetails`, {
      state: {
        packageData: data,
      },
    });
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    archiveSkuCategory(id)
      .then(() => {
        setInfo(t("SKU Category archived successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(dataFromApi);
      return;
    }
    const newRows = dataFromApi.filter(
      (row: any) =>
        row.name.toLowerCase().includes(value.toLowerCase()) ||
        row.code.toLowerCase().includes(value.toLowerCase())
    );
    setRows(newRows);
  };

  useEffect(() => {
    getData();
    setAddRecordfn(() => toCreate);
    setAddRecordLabel(t("Add SKU Category"));

    return () => {
      setAddRecordfn(() => {});
      setAddRecordLabel(t("Add Record"));
    };
  }, [i18n.language]);

  return (
    <ListPageDX
      listTitle={t("SKU Category")}
      name={t("SKU Category")}
      rows={rows}
      columns={columns}
      getRowId={(row: any) => row.skuCategoryId}
      isLoading={isLoading}
      onEdit={onEdit}
      onDelete={onDelete}
      editLabel={t("Preview")}
      setGridFilterCriteria={setGridFilterCriteria}
      exportToPDF={false}
      exportToCSV={false}
      showDatePicker={false}
      showSelect={false}
    />
  );
};

export default SKUCategory;
