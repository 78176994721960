import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  stepConnectorClasses,
  Tooltip,
  Zoom,
  Typography,
  useTheme,
  styled,
} from "@mui/material";
import { StepIconProps } from "@mui/material/StepIcon";
import { 
  CheckCircle as CheckCircleIcon, 
  Circle as CircleIcon, 
  LensOutlined as LensOutlinedIcon 
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { DATE_FORMAT } from "../../shared/globals";
import TypographyDX from "./typographydx";
import BoxDX from "./boxdx";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 12, 
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 4,
    border: 0,
    backgroundColor: theme.palette.divider, 
    borderRadius: 10,
  },
  [`&.${stepConnectorClasses.active}, &.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main, 
    },
  },
}));

const CustomStepIcon = (props: StepIconProps) => {
  const { active, completed } = props;
  const theme = useTheme();

  return active ? (
    <CircleIcon
      sx={{
        color: theme.palette.primary.main,
        fontSize: 24, 
        boxShadow: `0px 0px 4px ${theme.palette.primary.main}`,
        borderRadius: "50%",
      }}
    />
  ) : completed ? (
    <CheckCircleIcon 
      sx={{
        color: theme.palette.primary.main,
        fontSize: 24,
        boxShadow: `0px 0px 4px ${theme.palette.primary.main}`,
        borderRadius: "50%",
      mr:3,
      ml:3
      }}
    />
  ) : (
    <LensOutlinedIcon sx={{ color: theme.palette.grey[400], fontSize: 24 }} />
  );
};

const StatusDetails = ({ history }: { history?: any }) => {
  if (!history) {
    return <Typography variant="body1">No Data Available</Typography>;
  }

  return (
    <div>
      <TypographyDX variant="body1">
        Date: {history.statusDate ? moment(history.statusDate).format("MMM D, YYYY, HH:mm"): "No Date Available"}
      </TypographyDX>

      {history.notes && (
        <TypographyDX variant="body1">
          Notes: {history.notes}
        </TypographyDX>
      )}
      {history.createdByUserName && (
        <TypographyDX variant="body1">
          Created By: {history.createdByUserName}
        </TypographyDX>
      )}
    </div>
  );
};

const StatusProgressBarDX = ({ statuses, statusHistory }: any) => {
  const { t, i18n } = useTranslation();
  const getStepLabel = (status: any) => {
    const s = statuses.find((s:any)=> s && s.id === status);
    return i18n.language === 'en' ? s.en_value : s.ar_value;
  };

  return (
    <BoxDX
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
      }}
    >

    <Stepper 
      activeStep={statusHistory.length} 
      alternativeLabel
      connector={<ColorlibConnector/>}    
      sx={{
        display: 'flex',
        flexDirection: i18n.language === 'ar' ? 'row-reverse' : 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        '& .MuiStepLabel-label': {
          mt: 1,
          fontSize: '0.875rem',
          fontWeight: 'bold',
          textTransform: 'capitalize',
        },
      }} 
    >
      {statusHistory.map((history: any,index: number) => (
        <Tooltip
          key={index}
          arrow
          title={<StatusDetails history={history} />}
          placement='top'
          slots={{
            transition: Zoom,
          }}
          >
          <Step>
            <StepLabel StepIconComponent={CustomStepIcon}>
              {getStepLabel(history.status)}
            </StepLabel>
          </Step>
        </Tooltip>
      ))}
    </Stepper>
      </BoxDX>
  );
};

export default StatusProgressBarDX; 