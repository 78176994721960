import {
	Dialog,
	DialogContent,
	DialogTitle,
	Typography,
	Chip,
} from "@mui/material";
import BoxDX from "../../layout/boxdx";
import GridDX from "../../layout/griddx";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import { read, utils } from "xlsx";
import ButtonDX from "../../controls/buttondx";
import LoadingButtonDX from "../../controls/loadingbuttondx";
import useOutboundRequestService from "../../../shared/services/outboundrequestservice";
import { useNotificationContext } from "../../../context/notificationcontext";
import { useAuthContext } from "../../../context/authcontext";
import { useTenantContext } from "../../../context/tenantcontext";
import TextFieldDX from "../../controls/textfielddx";
import { arraysHaveSameElements } from "../../../shared/globals";

const OutboundRequestBulkForm = (props: any) => {
	const { t } = useTranslation();
	const { userData } = useAuthContext();
	const { setInfo, setError } = useNotificationContext();
	const { tenantId } = useTenantContext();
	const { bulkAddOutboundRequests } = useOutboundRequestService();

	const inputRef = useRef<HTMLInputElement>(null);
	const [file, setFile] = useState<File | null>(null);
	const [isSaving, setIsSaving] = useState(false);
	const [errors, setErrors] = useState("");

	const handleClick = () => inputRef.current?.click();

	const handleDragOver = (event: any) => {
		event.preventDefault();
		if (event.target.files) setFile(event.target.files[0]);
	};

	const handleDrop = async (event: any) => {
		event.preventDefault();
		if (event.target.files) setFile(event.target.files[0]);
	};

	const onChangeHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) setFile(e.target.files[0]);
	};

	const validateRow = (row: any) => {
		return Object.values(row).some(
			(value) => value !== undefined && value !== ""
		);
	};

	const onSave = async () => {
		const reqHeaders = [
			"CustomerName",
			"DeliveryType",
			"ShippingAddress",
			"WarehouseName",
			"ScheduleDate",
			"PocPhone",
			"PocName",
			"SkuName",
			"Quantity",
			"PackagingType",
			"Notes",
		];

		if (!file) {
			setError(t("Please select a file"));
			return;
		}

		setIsSaving(true);
		const buffer = await file.arrayBuffer();
		const workbook = read(buffer, { type: "array" });
		const worksheet = workbook.Sheets[workbook.SheetNames[0]];
		const data = utils.sheet_to_json(worksheet, { header: 1 });
		const headers = data[0] as string[];
		const rows = data.slice(1);

		if (!arraysHaveSameElements(reqHeaders, headers)) {
			setError(t("No valid rows to import"));
			setIsSaving(false);
			return;
		}

		const mappedData = rows
			.map((row: any) => {
				const order: any = {};
				headers.forEach((header, index) => {
					const key = headerMapping[header.toLowerCase()] || header;
					order[key] = row[index] ?? "";
				});
				return order;
			})
			.filter(validateRow);

		if (mappedData.length === 0) {
			setErrors(errors + t("No valid rows to import"));
		} else {
			bulkAddOutboundRequests(mappedData)
				.then((res) => {
					if (res === "") {
						setInfo(t("Orders imported successfully"));
						props.handleClose(true);
					} else {
						setError(t("Import finished with errors"));
						setErrors(errors + res);
					}
				})
				.catch((err) => setError(err))
				.finally(() => setIsSaving(false));
		}
	};

	const headerMapping: { [key: string]: string } = {
		customername: "customerName",
		deliverytype: "deliveryType",
		shippingaddress: "shippingAddress",
		warehousename: "warehouseName",
		scheduledate: "scheduleDate",
		pocphone: "pocPhone",
		pocname: "pocName",
		skuname: "skuName",
		skupackingtype: "skuPackingType",
		quantity: "quantity",
		notes: "notes",
	};

	return (
		<Dialog open={props.open} fullWidth maxWidth="md">
			<DialogTitle>
				<Typography variant="h6">{t("Bulk Import Orders")}</Typography>
			</DialogTitle>
			<DialogContent>
				<GridDX container spacing={2}>
					{errors === "" ? (
						<>
							<GridDX
								item
								xs={12}
								direction="column"
								justifyContent="center"
								alignItems="center"
								sx={{ mx: 2, height: "100%" }}
							>
								{file ? (
									<BoxDX display="flex" sx={{ width: 600 }}>
										<Chip
											label={file.name}
											color="primary"
											onDelete={() => setFile(null)}
										/>
									</BoxDX>
								) : (
									<BoxDX
										onDragOver={handleDragOver}
										onDrop={handleDrop}
										component="fieldset"
										sx={{
											border: "1px dashed #c6c6c6",
											borderRadius: 1,
											width: 600,
											height: 150,
										}}
									>
										<BoxDX
											display="flex"
											flexDirection="column"
											justifyContent="center"
											alignItems="center"
											direction="column"
										>
											<Typography
												sx={{
													fontSize: 18,
													textAlign: "center",
												}}
											>
												{t("Drag and Drop File here or")}
											</Typography>
											<ButtonDX variant="text" onClick={handleClick}>
												{t("Browse")}
											</ButtonDX>
										</BoxDX>
										<BoxDX sx={{ display: "none" }} justifyContent="center">
											<input
												ref={inputRef}
												accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
												type="file"
												name="file"
												style={{
													display: "none",
												}}
												onChange={onChangeHandler}
											/>
										</BoxDX>
									</BoxDX>
								)}
							</GridDX>
						</>
					) : (
						<GridDX
							item
							xs={12}
							direction="column"
							justifyContent="center"
							alignItems="center"
							sx={{ mx: 2, height: "100%" }}
						>
							<BoxDX
								display="flex"
								sx={{ width: 600, justifyContent: "space-between" }}
							>
								<TextFieldDX
									name="errors"
									label={t("Errors")}
									value={errors}
									multiline
									readOnly
								/>
							</BoxDX>
						</GridDX>
					)}
				</GridDX>
			</DialogContent>
			<GridDX container justifyContent="flex-end" p={2} gap={2}>
				<ButtonDX onClick={() => props.handleClose(false)}>
					{t("Cancel")}
				</ButtonDX>
				{errors === "" && (
					<LoadingButtonDX onClick={onSave} loading={isSaving} disabled={!file}>
						{t("Save")}
					</LoadingButtonDX>
				)}
			</GridDX>
		</Dialog>
	);
};

export default OutboundRequestBulkForm;
